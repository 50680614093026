import '@/styles/pages/Portfolio/SettingEdit.scss';

import React, { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { cloneDeep } from 'lodash';

import BaseButton from '@/components/common/Button/BaseButton';
import { ButtonIconButton } from '@/components/common/Button/IconButton';
import { FormCheckboxItem } from '@/components/common/Form/CheckboxItem';
import { FormContainerTextfield } from '@/components/common/Form/Container/Textfield';
import { FormLabel } from '@/components/common/Form/Label';
import { FormLayoutFieldset } from '@/components/common/Form/Layout/Fieldset';
import { FormLayoutUnitDivider } from '@/components/common/Form/Layout/UnitDivider';
import { FormRadioButtonItem } from '@/components/common/Form/RadioButtonItem';
import * as Page from '@/components/common/Page';
import HeaderImage from '@/components/common/Portfolio/HeaderImage';
import PortfolioNavigation from '@/components/common/Portfolio/Navigation';
import PortfolioHeaderEdit from '@/components/common/Portfolio/PortfolioHeaderEdit';
import HeaderTitle from '@/components/common/Title/HeaderTitle';
import { TooltipMessageMatchbou } from '@/components/common/Tooltip/Matchbou';
import { MESSAGE } from '@/definition/MESSAGE';
import { usePagePortfolio } from '@/hooks/usePagePortfolio';
import { useMBXMediaQuery } from '@/hooks/useMBXMediaQuery';
import { State } from '@/redux/state';
import {
    Portfolio, PortfolioStatusEnum, Status
} from '@/utils/api-client';

const defaultValues: Portfolio = {
  status: PortfolioStatusEnum.Closed,
  password: '',
  background_color: '',
  font: '',
  page_title: '',
  first_visit: 0,
  publish: 0,
  privacy: 0,
  page_title_logo: {
    f_id: '',
    f_thumbnail: '',
    f_url: '',
  },
  profile: {
    user_id: '',
    icon: '',
    name: '',
    introduction: '',
    experiences: [],
    skills: [],
    main_image: {
      f_id: '',
      f_thumbnail: '',
      f_url: '',
    },
  },
};

export function SettingEdit(): React.ReactElement {
  const user = useSelector((state: State) => state.user);
  const inputRef = useRef<HTMLInputElement>(null);
  const { userId, portfolio, patchPortfolio, setUserUpdate, setBannerImageUpdate, setBeforeUnload } = usePagePortfolio();
  const [pageTitleLogo, setPageTitleLogo] = useState<string | undefined>();
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const mq = useMBXMediaQuery();
  const history = useHistory();

  const methods = useForm<Portfolio>({
    defaultValues,
  });

  useEffect(() => {
    if (!portfolio) return;
    methods.setValue('status', portfolio.status);
    methods.setValue('privacy', portfolio.privacy);
    methods.setValue('page_title', portfolio.page_title);
    methods.setValue('page_title_logo', portfolio.page_title_logo);
    if (portfolio.profile)
      methods.setValue('profile', {
        ...portfolio.profile,
        user_id: portfolio.profile?.user_id || '',
      });
    setPageTitleLogo(portfolio.page_title_logo?.f_thumbnail);
    if (portfolio.status === 'password') {
      setPasswordVisible(true);
      methods.setValue('password', portfolio.password);
    }
  }, [portfolio]);

  useEffect(() => {
    const subscription = methods.watch((value, { name, type }) => {
      if (!userId) return;
      setBeforeUnload(true);
      setPasswordVisible(value.status === 'password');
    });

    return () => subscription.unsubscribe();
  }, [methods.watch, userId]);

  const onSubmit = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const value = methods.getValues();
    const newPortfolio = {...cloneDeep(portfolio), ...value} as Portfolio & Status;
    await patchPortfolio(newPortfolio);
    const button = e.target as HTMLDivElement;
    if (button.textContent === "反映を確認する") history.push(`/mypage/portfolio/${userId}/profile`);
  }

  const onClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    inputRef.current?.click();
  };

  // ロゴ画像変更
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) return;
    const imageFile = e.target.files[0];
    methods.setValue('page_title_logo', {
      f_id: "",
      f_url: imageFile as any,
      f_thumbnail: "",
    });
    const reader = new FileReader();
    reader.onload = () => {
      setPageTitleLogo(reader.result as string);
    };
    // 画像の読み込み
    reader.readAsDataURL(imageFile);
  };

  const copyURL: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    navigator.clipboard.writeText(`https://matchbox.work/portfolio/${userId}`);
  };
  const onPrivacy = () => {
    if (methods.watch('privacy') === 0) {
      methods.setValue('privacy', 1);
    } else {
      methods.setValue('privacy', 0);
    }
  };

  const logoClear = () => {
    if (!pageTitleLogo) return;
    setPageTitleLogo(undefined);
    methods.setValue('page_title_logo', undefined);
  };

  const style = portfolio?.updated_at ? { justifyContent: 'space-between' } : undefined;

  return (
    <div className="mbx-setting-edit">
      <Page.Wrapper
        header={
          <>
            <div className="sticky top-0 z-20">
              <HeaderTitle title={'ポートフォリオ'}>
                <div className="mbx-setting-edit-preview-wrapper" style={style}>
                  {portfolio?.updated_at && (
                    <p className="text-gray-700 text-10_10">最終更新：{portfolio?.updated_at}</p>
                  )}
                  <div className="flex justify-end sm:-mr-16 sp_none">
                    <BaseButton theme="secondary" size="40" width="large" className="pr-10" onClick={onSubmit}>
                      保存する
                    </BaseButton>
                    <BaseButton theme="primary" size="40" width="large" onClick={onSubmit}>
                      反映を確認する
                    </BaseButton>
                  </div>
                </div>
              </HeaderTitle>
              <div className="sp_only">
                <div className="pr-16 bg-gray-200 border-b border-gray-300 h-48 flex items-center	justify-end">
                  <BaseButton theme="secondary" size="40" width="large" className="pr-10" onClick={onSubmit}>
                    保存する
                  </BaseButton>
                  <BaseButton theme="primary" size="40" width="large" onClick={onSubmit}>
                    反映を確認する
                  </BaseButton>
                </div>
              </div>
            </div>
            <div className="sm:hidden">
            <HeaderImage
              cameraIcon={true}
              imageSrc={
                portfolio?.profile?.main_image.f_url
                  ? portfolio?.profile?.main_image.f_url
                  : '/images/header-img_default.png'
              }
              onChange={setBannerImageUpdate}
              onClick={() => setBannerImageUpdate('')}
              />
            </div>
          </>
        }
      >
        <>
          <div className="sm:hidden">
            <PortfolioHeaderEdit
              imageSrc={user?.icon ? user.icon : '/images/circle-placeholder.png'}
              onChange={setUserUpdate}
              portFolio={portfolio}
            />
          </div>

          <PortfolioNavigation
            menu={[
              { title: '作品', url: '/mypage/portfolio/edit' },
              { title: 'プロフィール', url: `/mypage/portfolio/${userId}/profile/edit` },
              { title: '設定', url: '/mypage/portfolio/setting' },
            ]}
            activeIndex={2}
          />

          <Page.Column
            main={
              <FormProvider {...methods}>
                <form>
                  <div className={cn(mq.sm ? "my-32" : "my-48")}>
                    <div className="flex items-center mb-24">
                      <h2 className="title mbx-typography--heading_4">ポートフォリオのタイトル</h2>
                      <TooltipMessageMatchbou
                        className="ml-4"
                        message="オファー機能ご利用時、「ポートフォリオのタイトル」は企業に開示されません。"
                        position={['0', '-94px']}
                      >
                        <ButtonIconButton
                          hitArea="mini"
                          iconName="Info"
                          type="gray"
                          focus={false}
                        />
                      </TooltipMessageMatchbou>
                    </div>

                    <p className="text-body_1">
                      文字のタイトル、画像ロゴ、どちらも設定した場合は画像ロゴが表示されます。
                    </p>

                    <div className="space-y-8 my-22">
                      <FormLabel label="文字のタイトル" type="small" />
                      <FormContainerTextfield
                        className="mkt_mask_items"
                        name="page_title"
                        placeholder="例）Ichiro Suzuki"
                      />
                    </div>

                    <div className="space-y-8 my-22">
                      <FormLabel label="画像ロゴ" type="small" />
                      <div className="lg:flex md:flex items-center lg:space-x-8 md:space-x-8">
                        <BaseButton theme="tertiary" size="m" onClick={onClick}>
                          画像をアップロード
                        </BaseButton>
                        <div className="w-272 h-40 mbx-setting-edit_bg-alpha sm:mb-16">
                          <div
                            className="w-272 h-40 mkt_mask_items"
                            style={{ backgroundImage: `url(${pageTitleLogo})` }}
                          />
                          <div className="pt-8 text-left">
                            <p className="text-caption_2 text-gray-700">横40px × 縦280px推奨、最大5MB</p>
                          </div>
                        </div>
                        <ButtonIconButton
                          iconName="Circle-large_Clear"
                          type="gray"
                          onClick={logoClear}
                        />
                      </div>
                      <input type="file" accept=".jpg, .jpeg, .png, .gif" ref={inputRef} onChange={onChange} />
                    </div>
                  </div>

                  <div className={cn(mq.sm ? "my-32" : "my-48")}>
                    <div className="flex items-center mb-24">
                      <h2 className="title mbx-typography--heading_4">
                        ポートフォリオWebページのURL
                      </h2>
                      <TooltipMessageMatchbou
                        className="ml-4"
                        message="オファー機能ご利用時、「ポートフォリオWebページのURL」は企業に開示されません。"
                        position={['0', '-94px']}
                      >
                        <ButtonIconButton
                          hitArea="mini"
                          iconName="Info"
                          type="gray"
                          focus={false}
                        />
                      </TooltipMessageMatchbou>
                    </div>
                    <FormLayoutFieldset>
                      <></>
                      <div className="md:flex lg:flex md:space-x-8 lg:space-x-8">
                        <div className="flex flex-1 space-x-8">
                          <FormLayoutUnitDivider>
                            https://matchbox.work/portfolio/
                          </FormLayoutUnitDivider>

                          <FormContainerTextfield
                            className="flex-1 mkt_mask_items"
                            name="profile.user_id"
                            placeholder=""
                            disabled
                          />
                        </div>

                        <BaseButton
                          theme="tertiary"
                          size="m"
                          className="mbx-setting-edit_copy-url"
                          onClick={copyURL}
                        >
                          URLをコピー
                        </BaseButton>
                      </div>
                    </FormLayoutFieldset>
                  </div>

                  <div className={cn(mq.sm ? "my-32" : "my-48")}>
                    <h2 className="title mbx-typography--heading_4 mb-24">プライバシー</h2>
                    <div className="flex items-center">
                      <FormRadioButtonItem
                        label="パスワードなしで公開"
                        value="public"
                        name="status"
                      />
                      <TooltipMessageMatchbou
                        className="ml-4"
                        message="URLを知っている人は誰でも見ることができます"
                        position={['0', '-94px']}
                      >
                        <ButtonIconButton
                          hitArea="mini"
                          iconName="Info"
                          type="gray"
                          focus={false}
                        />
                      </TooltipMessageMatchbou>
                    </div>
                    <div className="flex items-center">
                      <FormRadioButtonItem
                        label="パスワードを設定して公開"
                        value="password"
                        name="status"
                      />
                      <TooltipMessageMatchbou
                        className="ml-4"
                        message="URLとパスワードを知っている人は誰でも見ることができます"
                        position={['0', '-94px']}
                      >
                        <ButtonIconButton
                          hitArea="mini"
                          iconName="Info"
                          type="gray"
                          focus={false}
                        />
                      </TooltipMessageMatchbou>
                    </div>
                    {passwordVisible && (
                      <>
                        <FormContainerTextfield
                          name="password"
                          type="password"
                          placeholder="例）Match.123"
                          className="w-300 ml-32"
                        />
                        <p className="mbx-typography--caption_1 mt-8 ml-32 whitespace-pre">
                          {MESSAGE.password_specifications}
                        </p>
                        <p className="text-11_20 mbx-mypage--text mb-16 ml-42">
                          {MESSAGE.password_specification_supplement}
                        </p>
                      </>
                    )}
                    <FormRadioButtonItem
                      label="公開しない"
                      value="closed"
                      name="status"
                    />
                  </div>
                  <div className={cn("sm:mb-212", mq.sm ? "my-32" : "my-48")}>
                    <h2 className="title mbx-typography--heading_4 mb-24">
                      コンプライアンスについて
                    </h2>

                    <p className="text-body_1 my-24">
                      登録するポートフォリオ内の掲載作品に関して、下記事項に同意ください。
                      <br />
                      チェックを入れていただくとWebページがつくられます。
                    </p>

                    <FormCheckboxItem
                      label="第三者の著作権・肖像権、その他知的財産権を侵害していません。"
                      name="privacy"
                      onChange={onPrivacy}
                    />
                  </div>
                </form>
              </FormProvider>
            }
            menu={<></>}
          />
        </>
      </Page.Wrapper>
    </div>
  );
}
