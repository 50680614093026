import '@/styles/common/Portfolio/works_preview_image.scss';

import cn from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';

import Modal from '@/components/common/Modal';
import { useMBXMediaQuery } from '@/hooks/useMBXMediaQuery';
import {
    Embed, Mockup, PortfolioPage, PortfolioPagePPageLayoutEnum, Question
} from '@/utils/api-client';

import { ImageMobilesPreview } from './ImageMobilesPreview';
import { ImageResponsivePreview } from './ImageResponsivePreview';
import { Player } from './MainVisual/Player';

type TObject = {
  f_id: string;
  f_url: string;
  f_thumbnail: string;
  caption?: string;
};

type Props = {
  page: PortfolioPage;
};

export function WorksPreviewImage(props: Props): React.ReactElement {
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);
  const [modal, setModal] = useState<boolean>(false);
  const [modalContents, setModalContents] = useState<React.ReactElement>();
  const mq = useMBXMediaQuery();

  const layoutChecker = (p_contents: Question | TObject | Mockup | Embed) => {
    if ((p_contents as Mockup).m_type) return (p_contents as Mockup).m_type;
    if ((p_contents as Embed).embed) return 'embed';
    return props.page.p_page_layout;
  };

  const contentsList = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const list: any = [];
    if (!props.page.p_contents) return [];
    Array.from(props.page.p_contents?.values()).forEach((contents) => {
      const type = layoutChecker(contents);
      if (type === 'all' || type === 'sp') {
        if (!(contents as Mockup).m_contents?.find((c) => c?.f_url === '')) {
          list.push(contents);
        }
      }
      if (type === 'embed') {
        if ((contents as Embed).image?.f_url) {
          list.push(contents);
        }
      }
      if (type === props.page.p_page_layout) {
        if ((contents as TObject).f_url !== '') {
          list.push(contents);
        }
      }
    });
    return list;
  }, [props.page.p_contents]);

  const getWidth = (layout: PortfolioPagePPageLayoutEnum): number => {
    let gutter = 24;
    const gap = 16;
    switch (layout) {
      //one column
      case PortfolioPagePPageLayoutEnum.StyleV2:
        if (mq.sm) {
          return window.innerWidth - gutter * 2;
        } else if (mq.sms) {
          gutter = 16;
          return window.innerWidth - gutter * 2;
        } else {
          return 624;
        }
      //two columns
      default:
        if (mq.sm) {
          return (window.innerWidth - gutter * 2) / 2 - gap;
        } else if (mq.sms) {
          gutter = 16;
          return (window.innerWidth - gutter * 2) / 2 - gap;
        } else {
          return 300;
        }
    }
  };

  const getRatio = (layout: PortfolioPagePPageLayoutEnum) => {
    let ratio = (width: number) => (width / 1) * 1;
    switch (layout) {
      case PortfolioPagePPageLayoutEnum.StyleV2:
        ratio = (width: number) => (width / 3) * 2;
        break;
      case PortfolioPagePPageLayoutEnum.Style2:
        ratio = (width: number) => (width / 1) * 3;
        break;
      case PortfolioPagePPageLayoutEnum.Style4:
        ratio = (width: number) => (width / 5) * 7;
        break;
      case PortfolioPagePPageLayoutEnum.Style8:
        ratio = (width: number) => (width / 5) * 3;
        break;
    }
    return ratio;
  };

  useEffect(() => {
    const ratio = getRatio(props.page.p_page_layout);
    const width = getWidth(props.page.p_page_layout);
    setWidth(width);
    setHeight(ratio(width));
  }, [props.page]);

  const modalOpen = (el: React.ReactElement) => {
    setModalContents(el);
    setModal(true);
  };

  if(contentsList.length === 0) return <></>;

  return (
    <div
      className={cn(
        'mbx-works-preview-image',
        `mbx-works-preview-image_${props.page.p_page_layout}`
      )}
    >
      {contentsList.map((item: Mockup | Embed | TObject, index: number) => {
        const type = layoutChecker(item);
        return (
          <div key={index} className="mkt_mask_items">
            {type === 'all' && (item as Mockup).m_contents && (
              <div className={cn('work', `work-${props.page.p_page_layout}`)}>
                <div
                  className="thumbnail"
                  style={{ height }}
                  onClick={() =>
                    modalOpen(
                      <div className="flex justify-center items-center h-full">
                        <div
                          className="w-624 sm:w-screen sm:px-24 overflow-hidden"
                          style={{ height: height }}
                        >
                          <div className="h-full" style={{ transform: 'scale(1.2)' }}>
                            <ImageResponsivePreview images={(item as Mockup).m_contents} />
                          </div>
                        </div>
                      </div>
                    )
                  }
                >
                  <ImageResponsivePreview images={(item as Mockup).m_contents} />
                </div>
                <div className="mbx-typography--caption_1 text">
                  {(item as Mockup).m_description}
                </div>
              </div>
            )}
            {type === 'sp' && (item as Mockup).m_contents && (
              <div className={cn('work', `work-${props.page.p_page_layout}`)}>
                <div
                  className="thumbnail"
                  style={{ height }}
                  onClick={() =>
                    modalOpen(
                      <div className="flex justify-center items-center h-full">
                        <div className="w-624 sm:w-screen sm:px-24" style={{ height }}>
                          <ImageMobilesPreview
                            images={(item as Mockup).m_contents?.map((c) => c?.f_url)}
                          />
                        </div>
                      </div>
                    )
                  }
                >
                  <ImageMobilesPreview images={(item as Mockup).m_contents?.map((c) => c?.f_url)} />
                </div>
                <div className="mbx-typography--caption_1 text">
                  {(item as Mockup).m_description}
                </div>
              </div>
            )}
            {type === 'embed' && (item as Embed).image && (
              <div className={cn('work', `work-${props.page.p_page_layout}`)}>
                <div className="embedThumbnail" style={{ height }}>
                  <img src={(item as Embed).image?.f_url} alt="" />
                </div>
                <div className="embed">
                  <Player {...{ width, height, src: (item as Embed).embed }} />
                </div>
                <div className="mbx-typography--caption_1 mt-12 text">
                  {(item as Embed).caption}
                </div>
              </div>
            )}
            {type === props.page.p_page_layout && (item as TObject).f_url && (
              <div className={cn('work', `work-${props.page.p_page_layout}`)}>
                <div
                  className="thumbnail"
                  onClick={() => modalOpen(<img src={(item as TObject).f_url} alt="" />)}
                >
                  <img src={(item as TObject).f_url} alt="" />
                </div>
                <div className="mbx-typography--caption_1 mt-12 text">
                  {(item as TObject).caption}
                </div>
              </div>
            )}
          </div>
        );
      })}
      <Modal isOpen={modal} isClose={() => setModal(false)} className={'field-child'}>
        <div className={cn('field-content', `field-content-${props.page.p_page_layout}`)}>
          {modalContents}
        </div>
      </Modal>
    </div>
  );
}

export default WorksPreviewImage;
