import '@/styles/pages/Portfolio/ProfileEdit.scss';

import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import BaseButton from '@/components/common/Button/BaseButton';
import { ButtonTextLink } from '@/components/common/Button/TextLink';
import { FormContainerTextArea } from '@/components/common/Form/Container/TextArea';
import { FormContainerTextfield } from '@/components/common/Form/Container/Textfield';
import * as Page from '@/components/common/Page';
import {
    HeaderImage, PortfolioHeaderEdit, PortfolioNavigation, SkillBox
} from '@/components/common/Portfolio/';
import HeaderTitle from '@/components/common/Title/HeaderTitle';
import { usePagePortfolio } from '@/hooks/usePagePortfolio';
import { useMBXMediaQuery } from '@/hooks/useMBXMediaQuery';
import { State } from '@/redux/state';
import {
    CareerHistory, CareerHistoryApi, Portfolio, Question, Status, UserInfo
} from '@/utils/api-client';

function ProfileEdit(): React.ReactElement {
  const user = useSelector((state: State) => state.user);
  const { portfolio, setUserUpdate, patchPortfolio, setBannerImageUpdate, setBeforeUnload } = usePagePortfolio();
  const [career, setCareer] = useState<CareerHistory & Status & UserInfo>();
  const mq = useMBXMediaQuery();
  const history = useHistory();
  const answerList = [
    { question: 'わたしの好きなこと', answer: '' },
    { question: '最近の休日の過ごし方', answer: '' },
    { question: '好きな映画', answer: '' },
    { question: '好きな本', answer: '' },
    { question: '好きな音楽', answer: '' },
    { question: '好きな言葉', answer: '' },
    { question: '尊敬する人', answer: '' },
    { question: 'これまでの仕事で1番大変だったこと', answer: '' },
    { question: 'これまでの仕事で1番嬉しかったこと', answer: '' },
    { question: 'わたしが日々大切にしていること', answer: '' },
    { question: 'わたしを一言で表すと', answer: '' },
  ];
  const placeholder =
    '例）専門学校を卒業し、株式会社マッチワークスに入社。Webデザイナーとして受託案件のWeb制作に4年間従事。ページデザインやHTML、CSS、JavaScript、PHPでのサイト構築を一通り経験した後、UI/UX領域にスキルの幅を広げるため、株式会社マッチラボに転職。現在は、Webデザイナー経験を活かしながら、アプリ開発のUI/UXデザイナーに従事しています。';
  useEffect(() => {
    (async () => {
      if (!user?.user_id) return;
      const career = await new CareerHistoryApi().getCareerHistory(user.user_id);
      setCareer(career.data);
    })();
  }, [user?.user_id]);

  useEffect(() => {
    if (portfolio?.profile) {
      const copy = cloneDeep(portfolio?.profile);
      if (!copy.answers || copy.answers.length === 0) {
        copy.answers = answerList;
      }
      methods.setValue('profile', copy);
    }
  }, [portfolio]);

  const methods = useForm<Portfolio>();

  useEffect(() => {
    const subscription = methods.watch((value, { name, type }) => {
      if (!user?.user_id) return;
      setBeforeUnload(true);
    });

    return () => subscription.unsubscribe();
  }, [methods.watch, user?.user_id]);

  const upDateIntroduction = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    methods.setValue('profile.introduction', e.target.value);
  };

  const onSubmit = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const value = methods.getValues();
    const param: Portfolio = Object.assign(value);
    const newPortfolio = cloneDeep(portfolio);
    if (newPortfolio && newPortfolio.profile) {
      newPortfolio.profile = param.profile;
      await patchPortfolio(newPortfolio);
    }
    const button = e.target as HTMLDivElement;
    if (button.textContent === "反映を確認する" && user) history.push(`/mypage/portfolio/${user.user_id}/profile`);
  }

  const experiences = () => {
    if (!portfolio?.items) return;
    const box: Question[] = [];
    const pages = Array.from(portfolio?.items?.values()).map((item) => {
      return item.i_pages;
    });
    pages.forEach((page) => {
      page.forEach((p) => {
        if (p.p_page_layout === 'questions') {
          p.p_contents?.forEach((c) => {
            if ((c as Question).qa_id === 'b_v2_8') {
              box.push(c as Question);
            }
          });
        }
      });
    });
    const answersArray = box.map((experiences) => {
      return experiences.qa_answer;
    });
    const experiences: string[] = [];
    answersArray.forEach((answers) => {
      answers.forEach((a) => {
        experiences.push(a);
      });
    });
    return experiences.filter((x, s, self) => {
      return self.indexOf(x) === s;
    });
  };

  const style = portfolio?.updated_at ? { justifyContent: 'space-between' } : undefined;

  return (
    <div className="profile-edit">
      <Page.Wrapper
        header={
          <>
            <div className="sticky top-0 z-20">
              <HeaderTitle title={'ポートフォリオ'}>
                <div className="profile-edit-preview-wrapper" style={style}>
                  {portfolio?.updated_at && (
                    <p className="text-gray-700 text-10_10">最終更新：{portfolio?.updated_at}</p>
                  )}
                  {user && (
                    <div className="flex justify-end sm:-mr-16 sp_none">
                      <BaseButton theme="secondary" size="40" width="large" className="pr-10" onClick={onSubmit}>
                        保存する
                      </BaseButton>
                      <BaseButton theme="primary" size="40" width="large" onClick={onSubmit}>
                        反映を確認する
                      </BaseButton>
                    </div>
                  )}
                </div>
              </HeaderTitle>
              {user && (
                <div className="sp_only">
                  <div className="pr-16 bg-gray-200 border-b border-gray-300 h-48 flex items-center	justify-end">
                    <BaseButton theme="secondary" size="40" width="large" className="pr-10" onClick={onSubmit}>
                      保存する
                    </BaseButton>
                    <BaseButton theme="primary" size="40" width="large" onClick={onSubmit}>
                      反映を確認する
                    </BaseButton>
                  </div>
                </div>
              )}
            </div>
            <HeaderImage
              cameraIcon={true}
              imageSrc={
                portfolio?.profile?.main_image.f_url
                  ? portfolio.profile.main_image.f_url
                  : '/images/header-img_default.png'
              }
              onChange={setBannerImageUpdate}
              onClick={() => setBannerImageUpdate('')}
            />
          </>
        }
      >
        <>
          <PortfolioHeaderEdit
            imageSrc={user?.icon ? user?.icon : '/images/circle-placeholder.png'}
            onChange={setUserUpdate}
            portFolio={portfolio}
          />
          <PortfolioNavigation
            menu={[
              { title: '作品', url: '/mypage/portfolio/edit' },
              { title: 'プロフィール', url: `/mypage/portfolio/${user?.user_id}/profile/edit` },
              { title: '設定', url: '/mypage/portfolio/setting' },
            ]}
            activeIndex={1}
          />
          <Page.Column
            main={
              <>
                <FormProvider {...methods}>
                  <form>
                    <div className="introduction mt-48">
                      <p className="title mbx-typography--heading_4">自己紹介</p>
                      <div className="introduction-input mt-24">
                        <FormContainerTextArea
                          className={'text-area mkt_mask_items'}
                          name={'profile.introduction'}
                          maxLength={300}
                          placeholder={placeholder}
                          onChange={upDateIntroduction}
                          rows={6}
                        />
                      </div>
                    </div>
                    <div className="questionnaire mt-48 mb-96 md:mb-0">
                      <p className="title mbx-typography--heading_4">パーソナルアンケート</p>
                      {answerList.map((item, index) => {
                          return (
                            <div className="item mt-24" key={index}>
                              <p className="question mbx-typography--body_2">{item.question}</p>
                              <div className="answer mt-10">
                                <FormContainerTextfield
                                  name={`profile.answers.${index}.answer`}
                                  placeholder={'あなたの個性を伝えましょう'}
                                  className="mkt_mask_items"
                                />
                              </div>
                            </div>
                          );
                        })}
                    </div>
                    <div className="sm:mb-212"></div>
                  </form>
                </FormProvider>
              </>
            }
            menu={
              <>
                <div className={cn("skills pl-48 md:pl-0", mq.sm ? "mt-32" : "mt-48")}>
                  <p className="title mbx-typography--subhead_1">テクニカルスキル</p>
                  <p className="link">
                    <ButtonTextLink href={'/mypage/cv/edit#skill'}>職務経歴書</ButtonTextLink>
                    から編集できます
                  </p>
                  {career?.skills &&
                    career?.skills.map((item, index) => {
                      const level = item.level as 0 | 1 | 2 | 3 | 4 | 5;
                      const experience = item.experience as 1 | 2 | 3 | 4;
                      return (
                        <div className="skill mt-16" key={index}>
                          <SkillBox
                            skillName={item.name ? item.name : ''}
                            level={level}
                            experience={experience}
                          />
                        </div>
                      );
                    })}
                </div>
                <div className="experience pl-48 mt-48 md:pl-0 md:mb-96">
                  <p className="title mbx-typography--subhead_1">経験</p>
                  <p className="link">
                    <ButtonTextLink href={'/mypage/portfolio/edit'}>各作品の詳細</ButtonTextLink>
                    から編集できます
                  </p>
                  <p className="text mbx-typography--body_2 mt-16 mkt_mask_items">
                    {experiences()?.join(' / ')}
                  </p>
                </div>
              </>
            }
          />
        </>
      </Page.Wrapper>
    </div>
  );
}

export default ProfileEdit;
