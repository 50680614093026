import { AxiosError } from 'axios';
import '@/styles/pages/CareerHistory/Edit.scss';

import React, { useState, useEffect, useRef, useMemo } from 'react';
import { FieldValues, useForm, UseFormProps, UseFormReturn, FormProvider, SubmitHandler } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';

import * as CH from '@/components/CareerHistory';
import { ButtonIconButton } from '@/components/common/Button/IconButton';
import {
    FormContainer, FormContainerTextArea, FormContainerTextfield, FormLabel } from '@/components/common/Form/';
import * as Page from '@/components/common/Page';
import { TitleSectionTitle } from '@/components/common/Title/SectionTitle';
import { TooltipMessageMatchbou } from '@/components/common/Tooltip/Matchbou';
import { ButtonTextLink } from '@/components/common/Button/TextLink';
import { FormContainerDropdown } from '@/components/common/Form/Container/Dropdown';
import { FormLayoutFieldset } from '@/components/common/Form/Layout/Fieldset';
import Modal from '@/components/common/Modal';
import BaseButton from '@/components/common/Button/BaseButton';
import { CAREER_HISTORY_TEMPLATE } from '@/definition/CAREER_HISTORY_TEMPLATE';
import { useMBXMediaQuery } from '@/hooks/useMBXMediaQuery';
import { careerHistory, qualification, career } from '@/hooks/usePageCareerHistory';
import { Repeat } from '@/types/Util';
import { CareerHistory, ResponseError, Project, UserInfo, Status, CareerHistoryApi } from '@/utils/api-client';
import { progressFromCareerHistory } from '@/utils/utils';
import { useDispatch } from 'react-redux';
import { setSaved, setPrepareFrag, toggleLoading, notificationError } from '@/redux/index';

export default function Edit(): React.ReactElement {
  const mq = useMBXMediaQuery();
  const dispatch = useDispatch();
  const descriptionRef = useRef<HTMLDivElement>(null);
  const [CHData, setCHData] = useState<(CareerHistory & Status & UserInfo) | undefined>();
  const [template, setTemplate] = useState<boolean>(false);
  const [updatedKey, setUpdatedKey] = useState<string[]>([]);
  const [isFirstGet, setIsFirstGet] = useState<boolean>(false);
  const [toggleCareers, setToggleCareers] = useState<string[]>([]);
  const [deleteCareers, setDeleteCareers] = useState<string[]>([]);
  const [deleteProjects, setDeleteProjects] = useState<string[]>([]);
  const [toggleQualifications, setToggleQualifications] = useState<string[]>([]);
  const [deleteQualifications, setDeleteQualifications] = useState<string[]>([]);
  const [deleteskills, setDeleteskills] = useState<number[]>([]);
  const {
    data: fetchData,
    user,
    setDefaultValue,
    patch: patchCareerHistoryImmediately,
    deleteSkill,
    postQualification,
    postCareer,
    removeCareer,
    deleteQualification,
  } = careerHistory(true);
  const {
    patch: patchToggleQualification,
  } = qualification();
  const { patch, removeProject } = career();
  const history = useHistory();

  useEffect(() => {
    // 遷移して初回のみ取得する
    if (fetchData && !isFirstGet) {
      setCHData(fetchData);
      setIsFirstGet(true);
    }
  }, [fetchData]);

  const useFormProps: UseFormProps = {
    mode: 'onBlur',
    // FormContainerはfetchDataを監視しているが、fetchDataそのものをsetValueしないので、
    // defaultValuesを更新する必要がある
    defaultValues: setDefaultValue(CHData),
  };

  const methods = useForm(useFormProps);

  const progress = useMemo<Repeat<0 | 1, 4>>(
    () => progressFromCareerHistory(CHData),
    [CHData]
  );

  const templateMethods = useForm<{template: string}>({
    defaultValues: {
      template: CAREER_HISTORY_TEMPLATE.template_job[0].value,
    }
  });

  useEffect(() => {
    const subscription = templateMethods.watch((value, { name }) => {
      if (!name || !descriptionRef.current) return;
      descriptionRef.current.textContent = CAREER_HISTORY_TEMPLATE.template[value[name]];
    });
    return () => subscription.unsubscribe();
  }, [templateMethods.watch]);

  const placeholder = {
    advantage:`例）
①Webサイト構築およびアプリ開発におけるUI/UX設計（主にFigmaを使用）
②ラフ案およびワイヤーフレームの作成（主にAdobe XD、Photoshop、Illustratorを使用）
③フロントエンド領域でのコーディング（主にHTML、CSS、JavaScript、PHPを使用）`
  }

  const setBeforeUnload = (flag: boolean) => {
    dispatch(setPrepareFrag(flag));
  };

  const isInclude = (name: string) => {
    return updatedKey.some((key) => key.includes(name));
  }

  const onSubmit: SubmitHandler<any> = async (formData, e) => {
    const data: any = {
      latest_update: formData.latest_update,
    };
    dispatch(toggleLoading(true));
    try {
      // 更新があったkeyのみを変換して利用する
      Object.keys(formData).forEach(function (key) {
        if(!updatedKey.some(uKey => uKey.includes(key))) return;
        data[key] = formData[key];
      });

      // 資格・免許
      if ((isInclude('qualifications') || toggleQualifications.length !== 0) && formData.qualifications) {
        for (let i = 0; i < formData.qualifications?.length; i++) {
          const qualification = formData.qualifications[i];
          if (!qualification.q_id) {
            // 登録処理
            const res = await postQualification(qualification);
            formData.qualifications[i] = res?.data.qualifications[0];
          } else if (isInclude(`qualifications.${i}`) || toggleQualifications.includes(qualification.q_id)) {
            // 更新処理
            await patchToggleQualification(qualification, false);
          }
        }
        data.qualifications = formData.qualifications;
      }

      // 職歴
      if ((isInclude('careers') || toggleCareers.length !== 0) && formData.careers) {
        for (let i = 0; i < formData.careers?.length; i++) {
          const career = formData.careers[i];
          if (career.c_employee_count === "") career.c_employee_count = 0;
          if (career.c_capital === "") career.c_capital = 0;
          career.c_projects.forEach((project: Project) => {
            if (!project.id) delete project.id;
          });
          if (!career.c_id) {
            // 登録処理
            const res = await postCareer(career);
            formData.careers[i] = res?.data.careers[0];
          } else if (isInclude(`careers.${i}`) || toggleCareers.includes(career.c_id)) {
            // 更新処理
            const res = await patch(career);
            formData.careers[i] = res?.data.careers[0];
          }
        }
        data.careers = formData.careers;
      }

      // 職務経歴書更新
      await patchCareerHistoryImmediately(data, false);

      // 職歴削除処理
      if (deleteCareers.length !== 0) {
        for (let i = 0; i < deleteCareers.length; i++) {
          await removeCareer(deleteCareers[i]);
        }
        setDeleteCareers([]);
      }

      // 職歴・プロジェクト削除処理
      if (deleteProjects.length !== 0) {
        for (let i = 0; i < deleteProjects.length; i++) {
          await removeProject(Number(deleteProjects[i]));
        }
        setDeleteProjects([]);
      }

      // 資格・免許削除処理
      if (deleteQualifications.length !== 0) {
        for (let i = 0; i < deleteQualifications.length; i++) {
          await deleteQualification(deleteQualifications[i]);
        }
        setDeleteQualifications([]);
      }

      // スキル削除処理
      if (deleteskills.length !== 0) {
        for (let i = 0; i < deleteskills.length; i++) {
          await deleteSkill(deleteskills[i]);
        }
        setDeleteskills([]);
      }

      if (user?.user_id) {
        const res = await new CareerHistoryApi().getCareerHistory(user.user_id);
        setCHData(res.data);
      }

      setToggleQualifications([]);
      setToggleCareers([]);
      setUpdatedKey([]);
      setBeforeUnload(false);
      dispatch(setSaved(true));
      // 確認ボタンの場合はプレビューに進む
      const event = e as React.BaseSyntheticEvent<Record<string, unknown>, any, any> | undefined;
      const button = event?.nativeEvent?.submitter as HTMLButtonElement;
      if (button.textContent === "反映を確認する") history.push("/mypage/cv");
    } catch (e) {
      // ロールバック処理
      if (user?.user_id) {
        const res = await new CareerHistoryApi().getCareerHistory(user.user_id);
        const response = res.data;
        // 資格・免許
        if (response.qualifications && response.qualifications.length !== 0) {
          // 既に登録されていれば削除
          const reQualifications = response.qualifications;
          for (let i = 0; i < reQualifications.length; i++) {
            if (CHData?.qualifications && CHData.qualifications.filter((qualification) => qualification.q_id === reQualifications[i].q_id).length === 0) {
              await deleteQualification(reQualifications[i].q_id);
            }
          }
        }
        if (CHData?.qualifications && CHData.qualifications.length !== 0) {
          for (let i = 0; i < CHData.qualifications.length; i++) {
            // 更新処理
            await patchToggleQualification(CHData.qualifications[i], false, true);
          }
        }

        // 職歴
        if (response.careers && response.careers.length !== 0) {
          // 既に登録されていれば削除
          const reCareers = response.careers;
          for (let i = 0; i < reCareers.length; i++) {
            if (CHData?.careers && CHData.careers.filter((career) => career.c_id === reCareers[i].c_id).length === 0) {
              await removeCareer(reCareers[i].c_id);
            }
          }
        }
        if (CHData?.careers && CHData.careers.length !== 0) {
          for (let i = 0; i < CHData.careers.length; i++) {
            // 更新処理
            await patch(CHData.careers[i]);
          }
        }

        // スキル
        if (response.skills && response.skills.length !== 0) {
          // 既に登録されていれば削除
          const reSkills = response.skills;
          for (let i = 0; i < reSkills.length; i++) {
            if (reSkills[i].id && CHData?.skills && CHData.skills.filter((skill) => skill.id === reSkills[i].id).length === 0) {
              await deleteSkill(reSkills[i].id as number);
            }
          }
        }

        await patchCareerHistoryImmediately(CHData, false);
      }
      const error = e as AxiosError<ResponseError>;
      if (error.response) dispatch(notificationError(error.response.data.error_message));
    } finally {
      dispatch(toggleLoading(false));
    }
  }

  return (
    <Page.Wrapper
      header={
        <div className="sticky top-0 z-10">
          <CH.CareerHistoryHeader>
            <CH.PageMenuContent {...{ progress }} />
          </CH.CareerHistoryHeader>
        </div>
      }
    >
      <div className="mbx-page-career-history mb-40 sm:mb-188">
        <Page.Column
          main={
            <>
              <FormContainer
                {...{
                  useFormProps,
                  onSubmit,
                  fetchData: CHData,
                  id: "pagesCHEdit",
                  setUpdatedKey,
                  setBeforeUnload,
                  watchInitData: false,
                  methods: methods as UseFormReturn<FieldValues, Record<string, unknown>>,
                }}
              >
                <div id="summary" className="pt-56">
                  <TitleSectionTitle type="large" className="mt-12">
                    <div className="flex items-center">
                      <span className="mr-4">職務経歴概要</span>
                      <TooltipMessageMatchbou
                        className=""
                        message="オファー機能ご利用時、「氏名」は企業に開示されません。"
                        position={['0', '-94px']}
                      >
                        <ButtonIconButton
                          hitArea="mini"
                          iconName="Info"
                          type="gray"
                          focus={false}
                        />
                      </TooltipMessageMatchbou>
                    </div>
                  </TitleSectionTitle>

                  <div className="space-y-8 my-22">
                    <div className="flex items-center">
                      <FormLabel label="氏名" type="small" />
                      <small className="text-11_12">
                        <ButtonTextLink href="/mypage/profile">基本情報</ButtonTextLink>から編集できます
                      </small>
                    </div>
                    <p className="text-14_20 mkt_mask_items">{user?.name}</p>
                  </div>

                  <div className="space-y-8 my-22">
                    <FormLabel label="肩書き" type="small" />
                    <FormContainerTextfield
                      className="mkt_mask_items"
                      name="job"
                      placeholder="例）UI/UXデザイナー"
                      onFocus={() => setBeforeUnload(true)}
                      emptyClear
                    />
                  </div>

                  <div className="space-y-8 my-22">
                    <FormLabel label="職務経歴概要" type="small">
                      <div className="flex items-center -ml-12">
                        <TooltipMessageMatchbou
                          className=""
                          message="オファー機能ご利用時、「職務経歴概要」は企業に開示されます。そのため自作のポートフォリオサイトなどのURLを入力すると、リンク先のページも企業は閲覧できます。氏名などの個人情報を企業に開示したくない場合は、ご自身で必ずリンク先のページを確認・修正の上、ご入力をお願いします。"
                          position={['0', '-94px']}
                          widthClassName="w-376"
                        >
                          <ButtonIconButton
                            hitArea="mini"
                            iconName="Info"
                            type="gray"
                            focus={false}
                          />
                        </TooltipMessageMatchbou>
                        <ButtonTextLink
                          onClick={(e) => {
                            e.preventDefault();
                            templateMethods.setValue("template", CAREER_HISTORY_TEMPLATE.template_job[0].value);
                            setTemplate(true);
                          }}
                          className="text-11_12 ml-16"
                          bold
                        >
                          テンプレートから⼊⼒する
                        </ButtonTextLink>
                      </div>
                    </FormLabel>
                    <FormContainerTextArea
                      className="mkt_mask_items"
                      rows={6}
                      maxLength={300}
                      name="description"
                      placeholder="例）専門学校を卒業し、株式会社マッチワークスに入社。Webデザイナーとして受託案件のWeb制作に4年間従事。ページデザインやHTML、CSS、JavaScript、PHPでのサイト構築を一通り経験した後、UI/UX領域にスキルの幅を広げるため、株式会社マッチラボに転職。現在は、Webデザイナー経験を活かしながら、アプリ開発のUI/UXデザイナーに従事しています。"
                      onFocus={() => setBeforeUnload(true)}
                    />
                  </div>

                  <div className="space-y-8 my-22">
                    <FormLabel label="活かせる経験・スキル・得意分野" type="small" />
                    <FormContainerTextArea
                      className="mkt_mask_items"
                      rows={6}
                      maxLength={300}
                      name="advantage"
                      placeholder={placeholder?.advantage}
                      onFocus={() => setBeforeUnload(true)}
                    />
                  </div>
                </div>

                <div id="SECTION_CAREERS">
                  <CH.Careers
                    patchCareer={setToggleCareers}
                    setDeleteCareers={setDeleteCareers}
                    setDeleteProjects={setDeleteProjects}
                  />
                </div>

                <div id="qualification">
                  <CH.Qualifications
                    patchItem={setToggleQualifications}
                    setDeleteQualifications={setDeleteQualifications}
                  />
                </div>

                <div id="skill">
                  <CH.Skills
                    setDeleteskills={setDeleteskills}
                  />
                </div>

                <div id="pr">
                  <TitleSectionTitle type="large" className="mt-12">
                    自己PR
                  </TitleSectionTitle>

                  <div className="space-y-8 my-22">
                    <FormContainerTextArea
                      className="mkt_mask_items"
                      rows={6}
                      maxLength={1000}
                      name="pr"
                      placeholder="例）プロデューサーやディレクターからの指示内容を単に実行するだけでなく、市場や競合の外部環境分析を自ら行い、携わるプロダクトやサービスに、今必要なUI/UXとは何かを提案し実行してきた経験が私の強みと認識しています。ユーザーにとって利便性の高い操作性も当然重要ですが、常に変化するサービスデザイン全般を意識することで、長期的にご利用いただけるサイトやアプリをご提案、構築できると考えています。"
                      onFocus={() => setBeforeUnload(true)}
                    />
                  </div>
                </div>

                <div id="media">
                  <TitleSectionTitle type="large" className="mt-12">
                    職務経歴書の更新日
                  </TitleSectionTitle>

                  <CH.LastUpdate />
                </div>
              </FormContainer>
            </>
          }
          menu={
            <div className="mbx-naviInpage flex justify-end lg:py-64">
              {mq.lg && <CH.PageMenuContent {...{ progress }} />}
            </div>
          }
        />
        <Modal
          isOpen={template}
          isClose={() => setTemplate(false)}
          className="rounded-modalContainer"
        >
          <section className="max-w-784 w-784 md:w-screen sm:w-screen sm:h-auto overflow-hidden pt-48 px-24">
            <div className={cn('font-bold mt-20', mq.sm ? 'text-18_33' : 'text-21_34')}>
              <p className="mt-48">
                職務経歴概要テンプレート
              </p>
            </div>
            <div>
              <p className="mt-14 text-15_32">
                使⽤したいテンプレートを選択してください。
                <p className="mt-7 text-12_20">※テンプレートを挿⼊すると、すでに⼊⼒された内容は上書きされます。</p>
              </p>
              <FormProvider {...templateMethods}>
                <form>
                <div className="mt-26 flex justify-center">
                  <FormLayoutFieldset
                    className={cn(mq.sm ? 'w-308' : 'w-530')}
                  >
                    <FormContainerDropdown
                      className={cn('select mkt_mask_items', mq.sm ? 'w-308' : 'w-530')}
                      name="template"
                      selectList={CAREER_HISTORY_TEMPLATE.template_job}
                    ></FormContainerDropdown>
                    <div className="mkt_mask_items mt-26 rounded-4 w-full text-14_21 mbx-formContainer--textarea text-left bg-gray-100" ref={descriptionRef}>{CAREER_HISTORY_TEMPLATE.template.creative}</div>
                  </FormLayoutFieldset>
                </div>
                  <div className="pb-20 mb-48 sm:mb-72">
                    <div className="w-308 m-auto mt-48">
                      <BaseButton
                        theme="primary"
                        size="m"
                        className="w-full"
                        onClick={(e) => {
                          e.preventDefault();
                          methods.setValue("description", descriptionRef.current?.textContent);
                          setTemplate(false);
                        }}
                      >
                        このテンプレートを使って⼊⼒する
                      </BaseButton>
                    </div>
                  </div>
                </form>
              </FormProvider>
            </div>
          </section>
        </Modal>
      </div>
    </Page.Wrapper>
  );
}
