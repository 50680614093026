import React, { useEffect, Dispatch, SetStateAction, useRef } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import * as CH from '@/components/CareerHistory/';
import { useMBXMediaQuery } from '@/hooks/useMBXMediaQuery';
import { TProjectFormValues } from '@/types/Pages/CareerHistory';
import {
    closestCenter, DndContext, KeyboardSensor, PointerSensor, TouchSensor, useSensor, useSensors
} from '@dnd-kit/core';
import {
    SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy
} from '@dnd-kit/sortable';
import { Project } from '@/utils/api-client';

export const Projects: React.VFC<{
  index: number;
  setDeleteProjects: Dispatch<SetStateAction<string[]>>;
  cId: string;
}> = ({ index, setDeleteProjects, cId }) => {
  const isFirstRun = useRef(true);
  const mq = useMBXMediaQuery();
  const { control, getValues, setValue } = useFormContext<TProjectFormValues>();

  const { fields, append, move, remove } = useFieldArray<
    TProjectFormValues,
    `careers.${number}.c_projects`,
    'uid'
  >({
    control,
    name: `careers.${index}.c_projects` as `careers.${number}.c_projects`,
    keyName: 'uid',
  });

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const touchSensors = useSensors(
    useSensor(TouchSensor, {
      // Press delay of 250ms, with tolerance of 5px of movement
      activationConstraint: {
        delay: 150,
        tolerance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  useEffect(() => {
    // 初回スキップ
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    // 職歴の並び替え時に業務内容が統合されてしまうため元に戻す
    const fieldArray = fields as Project[];
    const c_projects: Project[] = [];
    if (fieldArray.length !== 0) {
      fieldArray.forEach((_, cIndex) => {
        const c_project = getValues(`careers.${index}.c_projects.${cIndex}` as `careers.${number}.c_projects.${number}`);
        c_projects.push(c_project);
      });
    }
    setValue(`careers.${index}.c_projects` as `careers.${number}.c_projects`, c_projects as never);
  }, [index]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDragEnd = ({ active, over }: any) => {
    if (active.id !== over.id) {
      const oldIndex = fields.map((field) => field.uid).indexOf(active.id);
      const newIndex = fields.map((field) => field.uid).indexOf(over.id);
      move(oldIndex, newIndex);
    }
  };

  const onAppend = async () => {
    const emptyProject: Project = {
      tools: '',
      structure: '',
      start_date: null,
      end_date: null,
      detail: '',
      name: '',
    };
    append(emptyProject);
  };

  return (
    <DndContext
      sensors={mq.sm ? touchSensors : sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      autoScroll={false}
    >
      <SortableContext
        items={fields.map((field) => ({ id: field.uid }))}
        strategy={verticalListSortingStrategy}
      >
        <div className="mb-8 mbx-utils-stack-v--16">
          <div>
            {fields.map((item: any, ind) => (
              <div key={item.uid} className="mbx-formUnit-education-box">
                <CH.DraggableProject
                  key={item.uid}
                  item={item}
                  parentIndex={index}
                  index={ind}
                  onRemove={() => {
                    remove(ind);
                    if (cId && item.id) {
                      setDeleteProjects((prevState) => {
                        return [...prevState, item.id]
                      });
                    }
                  }}
                />
              </div>
            ))}
          </div>

          <CH.DraggableSection nested={true} className="" title="業務内容" sortable={false}>
            <CH.AddButton onClick={() => onAppend()} />
          </CH.DraggableSection>
        </div>
      </SortableContext>
    </DndContext>
  );
};
