import React, { useState, useEffect } from 'react';
import { eventList } from '@/definition/EVENT_LIST';
import { State } from '@/redux/state';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

export const Ed143 = (): React.ReactElement => {
  const history = useHistory();
  const isAuth = useSelector((state: State) => state.isAuth);
  const [isLogin, setIsLogin] = useState(false);
  const [deadline, setDeadline] = useState(false);

  useEffect(() => {
    if (isAuth) {
      setIsLogin(true);
    } else {
      setIsLogin(false);
    }
  }, [isAuth]);

  useEffect(() => {
    const e = eventList.find(e => e.id === "0143");
    if (!e) return;
    const yyyymmdd = e.deadlineDate.split(' ')[0];
    const deadlineDate = new Date(`${yyyymmdd.replace(/-/g, "/")} 12:00:00`);
    const now = new Date;
    if (now > deadlineDate) setDeadline(true);
  }, []);

  const ctaClick = () => {
    const path = `${location.pathname}#redirect_entry`;
    localStorage.setItem('setEventAfterLogin', path);
    history.push('/entry');
  }

  const style = {
    img: 'border-gray-300 border-r border-b border-l'
  };
  return (
    <div className="event-contents">
      <div className="event-detail">
        <div className="md:-mx-16 sm:-mx-16">
          <img className={style.img} src="../images/Top/event/event_0143_mv.jpg" width="978" height="504" alt="" />
        </div>
        <h1 className="mt-56 sm:mt-30 sm:mb-8 text-29_46 sm:text-18_24 font-bold text-center">4/24（木）19:30 -<br />
          <span className="inline-block sm:mb-8 text-21_34 sm:text-15_22">Webデザイナー進化論 season3 <br className="md:hidden lg:hidden" />令和最新版プロトタイプ制作編</span><br />
          AI × Figmaで爆速！OOUIのキホンと実践</h1>
        {deadline &&
          <section className="mt-30 sm:mt-24 mb-40 sm:mb-18">
            <div className="flex flex-col items-center justify-center px-16 py-25 sm:p-16 bg-gray-100">
              <p className="mb-10 lg:mb-16 text-18_33 sm:text-14_21 font-bold">このセミナーの受付は終了しています。</p>
              <p className="text-16_28 sm:text-14_21">定期的にセミナーを開催しておりますので、<br className="sm:hidden" />最新の開催情報は<a className="textLink" href="../event/">こちら</a>よりご確認ください。</p>
            </div>
          </section>
        }
        <section className='mt-80 sm:mt-55'>
          <h2 className='text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6'>セミナーについて</h2>
          <div className="mt-30 sm:mt-16 mb-30 sm:mb-18 box-line">
            <img src="../images/Top/event/event_0049_image.jpg" width="980" height="374" alt="" />
          </div>
          <p className="text-16_28 sm:text-14_21 mt-30 sm:mt-16 mb-40 sm:mb-18">
            昨今のAIやFigmaの機能などのような目まぐるしく日々進化していく情報のキャッチアップに役立てていただくべく、今回は令和最新版のWeb制作の現場を知っていただくためのセミナーを開催いたします。<br />
            <br />
            オブジェクト指向UI（OOUI）の基本的な考え方から、それらを設計で用いるメリットや基本構造についてを実例をもとにハンズオンで解説を行っていただきます。<br />
            OOUIを用いての設計を最新AI・Figmaを組み合わせて爆速でプロトタイプ作成する令和最新の制作現場のイマを是非目撃してください。<br />
            今回はハンズオンの制作の模様を見ていただきますが、5・6月は今回の解説を行う予定ですのでそちらも是非合わせてご参加ください。<br />
            <br />
            ご登壇は現在UXデザイナーとして活躍中のタグチマリコ氏です。<br />
            質疑応答の時間もございますので、ディレクター・デザイナーとしてのスキルアップの機会としてぜひご参加ください。</p>
        </section>
        {!deadline && !isLogin &&
          <section className='mt-80 sm:mt-55 mb-40 sm:mb-18'>
            <div className='flex flex-col items-center justify-center'>
              <p className='mb-6 md:mb-12 lg:mb-12 text-16_28 sm:text-14_21'>MATCHBOXのアカウントをお持ちでない方</p>
              <p className='base-button mb-15 md:mb-24 lg:mb-24 text-16_28 sm:text-14_21'>
                <p className='button size-m theme-secondary flex items-center flex-col font-bold cursor-pointer' onClick={ctaClick}>
                  アカウントを新規で作成する
                  <span className='text-14_21 sm:text-12_20 text-center'>※セミナー参加にはMATCHBOXの事前登録が必要です。</span>
                </p>
              </p>
              <p className='text-16_28 sm:text-14_21'>アカウントをお持ちの方は<Link className='textLink font-bold' to="/login">こちら</Link>からログイン後、お申込みください。</p>
            </div>
          </section>
        }
        <section>
          <h2 className='text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6'>以下に該当する方にオススメです。</h2>
          <div className="mt-30 sm:mt-16 p-40 sm:p-24 box-line text-left">
            <ul className="text-16_28 sm:text-14_21">
              <li className="flex mb-4">
                <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>オブジェクト指向UI（OOUI）について興味のある方</span>
              </li>
              <li className="flex mb-4">
                <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>最新のWeb制作について興味のある方</span>
              </li>
              <li className="flex mb-4">
                <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>UI・UX・Webデザイナー、Webディレクターの方</span>
              </li>
              <li className="flex mb-4">
                <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>AIやFigmaをUXデザイナーがどのように使っているか知りたい方</span>
              </li>
              <li className="flex">
                <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>セミナーを踏まえて転職の相談をしたい方</span>
              </li>
            </ul>
          </div>
        </section>
        {!deadline && !isLogin &&
          <section className='mt-80 sm:mt-55 mb-40 sm:mb-18'>
            <div className='flex flex-col items-center justify-center'>
              <p className='mb-6 md:mb-12 lg:mb-12 text-16_28 sm:text-14_21'>MATCHBOXのアカウントをお持ちでない方</p>
              <p className='base-button mb-15 md:mb-24 lg:mb-24 text-16_28 sm:text-14_21'>
                <p className='button size-m theme-secondary flex items-center flex-col font-bold cursor-pointer' onClick={ctaClick}>
                  アカウントを新規で作成する
                  <span className='text-14_21 sm:text-12_20 text-center'>※セミナー参加にはMATCHBOXの事前登録が必要です。</span>
                </p>
              </p>
              <p className='text-16_28 sm:text-14_21'>アカウントをお持ちの方は<Link className='textLink font-bold' to="/login">こちら</Link>からログイン後、お申込みください。</p>
            </div>
          </section>
        }
        <section>
          <h2 className='text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6'>講師プロフィール</h2>
          <div className='event-instructor box-line mb-30 sm:p-16'>
            <div className="instructor-photo">
              <img src="../images/Top/event/event_0093_prof.jpg" alt="" />
            </div>
            <dl className="instructor-prof sm:mt-16">
              <dt className="instructor-name text-16_28 font-bold">
                タグチ マリコ
              </dt>
              <dd className="instructor-position text-16_28 sm:text-14_21 mb-40 sm:mb-18">
                UXデザイナー / クリエイティブストラテジスト / HCD-Net認定 人間中心設計専門家
              </dd>
              <dd className="instructor-career text-16_28 sm:text-14_21">
                1994年東京都生まれ。高校在学中にデザイナーとして活動をはじめ、複数の制作会社・開発会社にて、広告・Web・アプリケーション開発のプレイヤーからマネージャー・経営者を経験。現在は、UXデザイン・人間中心設計（HCD）を起点としたサービスデザイン、ブランドデザイン、プロトタイピング・調査分析、UI・ビジュアルデザイン・イラスト、企業顧問・人材育成・コミュニティデザインなど、表層デザインから哲学まで、多角的にデザイン行為の在野研究をつづけている。
              </dd>
            </dl>
          </div>
        </section>
        <section>
          <h2 className="text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6">当日スケジュール</h2>
          <table className="index timetable text-16_28 sm:text-14_21 mb-30 sm:mb-12">
            <tbody>
              <tr>
                <th scope="row" className="bg-gray-50 font-normal whitespace-nowrap">
                  <span className="sm:block">19：30</span><span className="sm:block">～</span><span className="sm:block">20：50</span>
                </th>
                <td>
                  <ul className="text-16_28 sm:text-14_21">
                    <li className="flex"><span>（0）</span><span>はじめに
                      <ul className="text-16_28 sm:text-14_21">
                        <li className="flex"><span>0-1.&nbsp;</span><span>本日の流れ</span></li>
                      </ul></span></li>
                    <li className="flex"><span>（1）</span><span>OOUIの基本概念
                      <ul className="text-16_28 sm:text-14_21">
                        <li className="flex"><span>1-1.&nbsp;</span><span>OOUIとは？ 基本の考え方</span></li>
                        <li className="flex"><span>1-2.&nbsp;</span><span>OOUIを構成する3要素</span></li>
                        <li className="flex"><span>1-3.&nbsp;</span><span>OOUIの設計メリット</span></li>
                      </ul></span></li>
                    <li className="flex"><span>（2）</span><span>オブジェクトの考え方
                      <ul className="text-16_28 sm:text-14_21">
                        <li className="flex"><span>2-1.&nbsp;</span><span>「XXX」を従来UIとOOUIで考えてみる</span></li>
                        <li className="flex"><span>2-2.&nbsp;</span><span>オブジェクト図の基本構造</span></li>
                      </ul></span></li>
                    <li className="flex"><span>（3）</span><span>AIを活用した爆速オブジェクト図作成
                      <ul className="text-16_28 sm:text-14_21">
                        <li className="flex"><span>3-1.&nbsp;</span><span>AIを使って爆速でオブジェクト図を作成する方法</span></li>
                        <li className="flex"><span>3-2.&nbsp;</span><span>FigJamに取り込んで整理する</span></li>
                        <li className="flex"><span>3-3.&nbsp;</span><span>AIを活用する際の注意点</span></li>
                      </ul></span></li>
                    <li className="flex"><span>（4）</span><span>質疑応答
                    <ul className="text-16_28 sm:text-14_21">
                        <li className="flex"><span>4-1.&nbsp;</span><span>今日のまとめ（完成図）</span></li>
                        <li className="flex"><span>4-2.&nbsp;</span><span>次回予告</span></li>
                        <li className="flex"><span>4-3.&nbsp;</span><span>質疑応答</span></li>
                      </ul></span></li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </section >
        {!deadline && !isLogin &&
          <section className='mt-80 sm:mt-55 mb-40 sm:mb-18'>
            <div className='flex flex-col items-center justify-center'>
              <p className='mb-6 md:mb-12 lg:mb-12 text-16_28 sm:text-14_21'>MATCHBOXのアカウントをお持ちでない方</p>
              <p className='base-button mb-15 md:mb-24 lg:mb-24 text-16_28 sm:text-14_21'>
                <p className='button size-m theme-secondary flex items-center flex-col font-bold cursor-pointer' onClick={ctaClick}>
                  アカウントを新規で作成する
                  <span className='text-14_21 sm:text-12_20 text-center'>※セミナー参加にはMATCHBOXの事前登録が必要です。</span>
                </p>
              </p>
              <p className='text-16_28 sm:text-14_21'>アカウントをお持ちの方は<Link className='textLink font-bold' to="/login">こちら</Link>からログイン後、お申込みください。</p>
            </div>
          </section>
        }
        <section className="mb-64 sm:mb-48">
          <h2 className="text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 sm:mb-8 sm:pb-6 text-left">開催概要</h2>
          <table className="summary text-16_28 sm:text-14_21">
            <tbody>
              <tr>
                <th scope="row" className="font-normal text-left">開催日時</th>
                <td>2025/4/24（木）19:30～<br />
                  [入室開始] 19:20～</td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">開催方法</th>
                <td>Zoom開催<br />参加者の皆さまには別途URLをお送りします。</td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">参加費</th>
                <td>無料</td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">応募手順</th>
                <td>
                  <dl>
                    <dt className="mb-18 sm:mb-8">1.参加申し込み</dt>
                    <dd className="mb-38 sm:mb-16">MATCHBOXへログインいただき、お申し込みください。（アカウント作成は<a className="textLink"
                      href="https://matchbox.work/entry">こちら</a>から）</dd>
                    <dt className="mb-18 sm:mb-8">2.ご連絡</dt>
                    <dd className="mb-38 sm:mb-16">マッチボックス セミナー事務局より、MATCHBOXに登録していただいているメールアドレス宛に当日の詳細のご連絡差し上げます。</dd>
                    <dt className="mb-18 sm:mb-8">3.開催当日</dt>
                    <dd>当日はZoomにて開催いたします。</dd>
                  </dl>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        {!deadline && !isLogin &&
          <section className='mt-80 sm:mt-55 mb-40 sm:mb-18'>
            <div className='flex flex-col items-center justify-center'>
              <p className='mb-6 md:mb-12 lg:mb-12 text-16_28 sm:text-14_21'>MATCHBOXのアカウントをお持ちでない方</p>
              <p className='base-button mb-15 md:mb-24 lg:mb-24 text-16_28 sm:text-14_21'>
                <p className='button size-m theme-secondary flex items-center flex-col font-bold cursor-pointer' onClick={ctaClick}>
                  アカウントを新規で作成する
                  <span className='text-14_21 sm:text-12_20 text-center'>※セミナー参加にはMATCHBOXの事前登録が必要です。</span>
                </p>
              </p>
              <p className='text-16_28 sm:text-14_21'>アカウントをお持ちの方は<Link className='textLink font-bold' to="/login">こちら</Link>からログイン後、お申込みください。</p>
            </div>
          </section>
        }
        <section className='mt-80 sm:mt-55'>
          <h3 className='text-18_24 sm:text-14_21 font-bold'>開催について</h3>
          < p className='text-16_28 sm:text-14_21 mt-30 sm:mt-16'>
            Web・IT業界で必要不可欠な存在であるWebディレクター・Webデザイナーの皆さまを支援するために、日本ディレクション協会で受講者累計2, 000名を超える「0からのWebディレクション講座」を生み出したメンバーでもある一般社団法人ディレクションサポート協会 会長の助田正樹氏と連携して、Webの現場最前線で働くロールモデルの方々をゲストとしてお呼びし、キャリアを考えるために有益な情報提供を開始しています。          </p >
        </section >
        <section className="mt-80 sm:mt-55 p-40 sm:p-24 bg-gray-50 text-left">
          <h3 className="text-18_24 sm:text-14_21 font-bold">注意点</h3>
          <ul className="mt-16 text-16_28 sm:text-14_21">
            <li className="flex"><span>・</span><span>セミナー開催用のZoomURLはセミナー前日・当日にメールにてお送りします。</span></li>
            <li className="flex"><span>・</span><span>セミナー開催用のZoomURLをお知らせするメールはマッチボックスにご登録いただいているメールアドレス（ログインID）に送付しております。</span></li>
            <li className="flex"><span>・</span><span>受信フォルダ・迷惑メール設定、フォルダの受信容量やドメインの許可（@matchbox.work）の確認をお願いいたします。</span></li>
            <li className="flex"><span>・</span><span>マッチボックス登録時のアドレス、またはセミナー申込み時に入力したアドレスにURLへ届いていない場合は<a className='textLink' href='https://survey.mynavi.jp/cre/?enq=2va0sJC992s%3d' target={'_blank'} rel="noreferrer">こちら<i className="mbx-icon mbx-icon-External_link"></i></a>からお問い合わせください。</span></li>
          </ul>
        </section>
        <p className="text-center mt-120 sm:mt-55">
          <a className="textlink_back text-16_16 sm:text-14_14" href="./"><i className="mbx-icon mbx-icon-Arrow_Right"></i><span className="label pl-16">イベント一覧ページに戻る</span></a>
        </p>
      </div>
    </div>
  );
};
