import { useState } from 'react';
import * as yup from 'yup';

import { YUP_VALIDATION } from '@/definitionDirect/VALIDATION';
import {
  CommonSCMcExperiencedJobObjectModel,
  DirectAdminSearchConditionModel,
  DirectSearchConditionModel,
} from '@/utils/api-client';
import {
  conversionStringsLikeNumbers,
  conversionValuesForPeriods,
  excludeValuesForSearchCondition,
  objectEquals,
  reduceExperiencedJobArray,
} from '@/utils/utils';

type TReturn = {
  initValues: DirectSearchConditionModel; // フォームの初期値 これと比較して変更があったかどうか判定
  isChanged: boolean; // 変更があれば true なければ false
  searchConditionChangeDetection: (changeData: DirectSearchConditionModel) => void; // 変更判定処理
};

export const baseValidation = {
  matchbox_id: YUP_VALIDATION.matchbox_id,
  q_toeic_score: YUP_VALIDATION.toeic_score,
  q_toefl_score: YUP_VALIDATION.toefl_score,
  offer_update_at: YUP_VALIDATION.dateRange,
  'offer_update_at-from': YUP_VALIDATION.date,
  'offer_update_at-to': YUP_VALIDATION.date,
  age: YUP_VALIDATION.ageRange,
  'age-from': YUP_VALIDATION.age,
  'age-to': YUP_VALIDATION.age,
  career_history_amount: YUP_VALIDATION.numberRange(1, 11),
  portfolio_item_count: YUP_VALIDATION.numberRange(0, 16),
};

/**
 * 検索条件フォーム用の validation 設定
 */
export const validationSchemaForPost = yup.object().shape({
  ...baseValidation,
});

/**
 * 検索条件編集用の validation 設定
 */
export const validationSchemaForEdit = yup.object().shape({
  search_condition_name: YUP_VALIDATION.required,
  ...baseValidation,
});

/**
 * Direct Admin 共通の項目
 */
export const initSearchConditionValuesBase: DirectAdminSearchConditionModel = {
  keyword: [],
  offer_status: [],
  rate: [],
  offer_update_at: [],
  matchbox_id: '',
  gender: undefined,
  age: [],
  mc_experienced_job: [
    {
      job: undefined,
      job_year: [],
    },
  ],
  mc_preferred_change_date: [],
  prefecture: undefined,
  mc_situation: undefined,
  career_history_current_companies: '',
  career_history_current_section: '',
  career_history_current_post: '',
  mc_yearly_income: [],
  portfolio_item_count: [],
  career_history_amount: [],
  career_history_companies: [],
  career_history_section: [],
  career_history_post: [],
  qa_answer: [],
  career_history_experiences: [],
  career_history_tools: [],
  mc_maximum_management_count: [],
  q_title: [],
  q_toeic_score: undefined,
  q_toefl_score: undefined,
  mc_english_skills: undefined,
  e_school_name: [],
  final_education: [],
  mc_preferred_job_type: [],
  mc_preferred_business_type: [],
  mc_preferred_prefecture: [],
  mc_preferred_yearly_income: [],
  mc_preferred_employment_status: [],
  mc_conditions: [],
  has_own_portfolio: undefined,
};

const initSearchConditionValues: DirectSearchConditionModel = {
  ...initSearchConditionValuesBase,
  registration_date: undefined,
  last_login: undefined,
  last_update_portfolio: undefined,
  last_update_career_history: undefined,
  last_update_own_portfolio: undefined,
};

/**
 * Direct と Admin で共通して数値に変換する必要がある要素
 */
export const searchConditionNumberPropertiesBase = [
  'offer_status',
  'gender',
  'rate',
  'age',
  'job',
  'job_year',
  'mc_yearly_income',
  'portfolio_item_count',
  'mc_preferred_change_date',
  'prefecture',
  'mc_situation',
  'q_toeic_score',
  'q_toefl_score',
  'portfolio_item_count',
  'career_history_amount',
  'mc_maximum_management_count',
  'mc_english_skills',
  'final_education',
  'mc_preferred_job_type',
  'mc_preferred_business_type',
  'mc_preferred_prefecture',
  'mc_preferred_yearly_income',
  'mc_experienced_job',
  'mc_preferred_employment_status',
  'mc_conditions',
];

/**
 * Directで数値に変換する必要がある要素
 */
export const searchConditionNumberProperties = [
  ...searchConditionNumberPropertiesBase,
  'registration_date',
  'last_login',
  'last_update_portfolio',
  'last_update_career_history',
  'last_update_own_portfolio',
];

/**
 * 期間を変換する必要がある要素
 */
export const searchConditionPeriodsProperties = [
  'age',
  'offer_update_at',
  'career_history_amount',
  'portfolio_item_count',
];

/**
 * 検索条件が変更されたかチェック
 */
export const useSearchConditionValidation = (): TReturn => {
  const [isChanged, setIsChanged] = useState(false);
  // 検索条件の初期値 仕様書から構造とってくる
  // https://matchbox-2021-doc-api-git-develop-prty.vercel.app/#operation/post-direct-search_condition
  const initValues: DirectSearchConditionModel = initSearchConditionValues;

  /**
   * データに変更が発生したか検出
   */
  const searchConditionChangeDetection = (changeData: DirectSearchConditionModel) => {
    let convertValue = conversionValuesForPeriods(
      changeData as Record<string, unknown>,
      searchConditionPeriodsProperties
    );

    convertValue = conversionStringsLikeNumbers(convertValue, searchConditionNumberProperties);
    if (convertValue.mc_experienced_job) {
      convertValue.mc_experienced_job = reduceExperiencedJobArray(
        convertValue.mc_experienced_job as Array<CommonSCMcExperiencedJobObjectModel>
      );
    }

    // 編集データの場合に不要なプロパティを除外
    convertValue = excludeValuesForSearchCondition(convertValue);

    setIsChanged(
      !objectEquals(initValues as Record<string, unknown>, convertValue as Record<string, unknown>)
    );
  };

  return { initValues, isChanged, searchConditionChangeDetection };
};
