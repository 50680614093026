import React, { useMemo } from 'react';
import { cloneDeep } from 'lodash';
import Back from '@/components/common/Navigation/Page/Back';
import { TitleSectionTitle } from '@/components/common/Title/SectionTitle';

import {
  MainVisual,
  PortforioSection,
  PageMenuContent,
  PortfolioEditSummary,
  PortfolioEditManagement,
  PortfolioEditPoints,
  PortfolioEditImages,
} from '@/components/common/Portfolio';

import * as Page from '@/components/common/Page';
import { useMBXMediaQuery } from '@/hooks/useMBXMediaQuery';
import { useDispatch } from 'react-redux';
import { setPortfolio } from '@/redux';
import { PortfolioItem } from '@/utils/api-client';
import { usePagePortfolio } from '@/hooks/usePagePortfolio';
import { useDetailEdit } from '@/hooks/Portfolio/useDetailEdit';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';
import { BaseButton } from '@/components/common/Button/';
import HeaderTitle from '@/components/common/Title/HeaderTitle';

function DetailEdit(): React.ReactElement {
  const mq = useMBXMediaQuery();
  const { userId, portfolio, oldPortfolio, patchPortfolio, setBeforeUnload } = usePagePortfolio();

  const dispatch = useDispatch();
  const history = useHistory();
  const id = history.location.pathname.split('/')[4];

  const path = useMemo((): string => {
    if (userId === undefined) return '/error';
    return `/mypage/portfolio/${userId}/${id}`;
  }, [userId]);

  const {
    pfItem,
    pfItemId,
    setQuestionParam,
    setItem,
  } = useDetailEdit();

  const questionFields = [
    { qa_id: 'b_v2_1', qa_category: '関わり方', qa_question: '職種', qa_answer: [{ answer: '' }] },
    {
      qa_id: 'b_v2_2',
      qa_category: '関わり方',
      qa_question: '担当内容',
      qa_answer: [{ answer: '' }],
    },
    { qa_id: 'b_v2_8', qa_category: '関わり方', qa_question: '経験', qa_answer: [{ answer: '' }] },
    {
      qa_id: 'b_v2_7',
      qa_category: '関わり方',
      qa_question: '使用ツール',
      qa_answer: [{ answer: '' }],
    },
  ];

  const questionPlaceholder = [
    'Webデザイナー',
    'Webデザイン',
    'デザインフォーマットの作成',
    'Photoshop',
  ];

  const linkToTop = () => {
    history.push('/mypage/portfolio/edit');
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updatePortfolioItem = (i_param: any) => {
    if (!portfolio) return;

    const items: PortfolioItem[] = Array.from(portfolio.items?.values() || []).map((item) => {
      if (item.i_id !== pfItemId) return item;
      return {
        ...item,
        ...i_param,
      };
    });

    const newPortfolio = {
      ...Object.assign(portfolio),
      items,
    };

    dispatch(setPortfolio(newPortfolio));
  };

  const onUpdateSections = (qId: string, sections: string[]) => {
    setQuestionParam(qId, sections);
  };

  const onUpdateQuestion = (qId: string, sections: string[]) => {
    setQuestionParam(qId, sections);
  };

  const onUpdatePoints = (qaId: string, question: string, answer: string[]) => {
    setQuestionParam(qaId, answer, question);
  };

  const onSubmit = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    await patchPortfolio();
    const button = e.target as HTMLDivElement;
    if (button.textContent === "反映を確認する") history.push(path);
  }

  const style = portfolio?.updated_at ? { justifyContent: 'space-between' } : undefined;

  return (
    <div className="portfolio-detail">
      <Page.Wrapper
        header={
          <div className="sticky top-0 z-10">
            <HeaderTitle title={'ポートフォリオ'}>
              <div className="portfolio-detail-preview-wrapper" style={style}>
                {portfolio?.updated_at && (
                  <p className="text-gray-700 text-10_10">最終更新：{portfolio?.updated_at}</p>
                )}
                {userId && (
                  <div className="flex justify-end sm:-mr-16 sp_none">
                    <BaseButton theme="secondary" size="40" width="large" className="pr-10" onClick={onSubmit}>
                      保存する
                    </BaseButton>
                    <BaseButton theme="primary" size="40" width="large" onClick={onSubmit}>
                      反映を確認する
                    </BaseButton>
                  </div>
                )}
              </div>
            </HeaderTitle>
            {userId && (
              <div className="sp_only">
                <div className="pr-16 bg-gray-200 border-b border-gray-300 h-48 flex items-center	justify-end">
                  <BaseButton theme="secondary" size="40" width="large" className="pr-10" onClick={onSubmit}>
                    保存する
                  </BaseButton>
                  <BaseButton theme="primary" size="40" width="large" onClick={onSubmit}>
                    反映を確認する
                  </BaseButton>
                </div>
              </div>
            )}
          </div>
        }
      >
        <div className="sm:mb-212">
          <Page.Column
            main={
              <>
                <Back onClick={() => linkToTop()}>作品一覧へ</Back>

                <MainVisual
                  item={pfItem}
                  updatePortfolioItem={updatePortfolioItem}
                  oldPortfolio={oldPortfolio}
                />

                <div id="summary">
                  <TitleSectionTitle type="large" className="mt-12">
                    作品概要
                  </TitleSectionTitle>
                  <PortfolioEditSummary
                    portfolio={portfolio}
                    itemId={pfItemId}
                    setItem={setItem}
                    onUpdateQuestion={onUpdateQuestion}
                    setBeforeUnload={setBeforeUnload}
                  />
                </div>

                <PortforioSection
                  qaId="a_2"
                  portfolio={portfolio}
                  itemId={pfItemId}
                  onUpdateSections={(sections) => onUpdateSections('a_2', sections)}
                  title="受賞歴"
                  placeholder="例）カンヌライオンズ2019 モバイル部門 グランプリ"
                  setBeforeUnload={setBeforeUnload}
                />

                <div id="relation">
                  <TitleSectionTitle type="large" className="mt-12">
                    案件への関わり方
                  </TitleSectionTitle>
                  {questionFields.map((value, index) => (
                    <PortforioSection
                      key={index}
                      title={value.qa_question}
                      qaId={value.qa_id}
                      portfolio={cloneDeep(portfolio)}
                      itemId={pfItemId}
                      onUpdateSections={(sections) => onUpdateSections(value.qa_id, sections)}
                      placeholder={questionPlaceholder[index]}
                      setBeforeUnload={setBeforeUnload}
                    />
                  ))}
                </div>

                <PortfolioEditManagement
                  portfolio={cloneDeep(portfolio)}
                  itemId={pfItemId}
                  onUpdateQuestion={onUpdateQuestion}
                />

                <div id="point">
                  <TitleSectionTitle type="large" className="mt-12">
                    制作のポイント
                  </TitleSectionTitle>
                  <PortfolioEditPoints
                    portfolio={cloneDeep(portfolio)}
                    itemId={pfItemId}
                    onUpdatePoints={onUpdatePoints}
                    setBeforeUnload={setBeforeUnload}
                  />
                </div>

                <div id="media">
                  <TitleSectionTitle type="large" className="mt-12">
                    画像・動画など
                  </TitleSectionTitle>
                  <PortfolioEditImages
                    portfolio={portfolio}
                    itemId={pfItemId}
                    setBeforeUnload={setBeforeUnload}
                  />
                </div>
              </>
            }
            menu={
              <div className="flex items-end flex-col">
                <div className="mbx-naviInpage flex justify-end lg:pt-64">
                  {mq.lg && <PageMenuContent />}
                </div>
                {mq.lg && (
                  <div className="mt-30 w-180 lg:w-240 border-t border-b border-gray-400">
                    <div className="py-30">
                      <a
                        href="/doc/image-size.pdf"
                        target="_blank"
                        rel="noreferrer"
                        className="flex items-center justify-center text-12_12 font-bold text-blue-700"
                      >
                        推奨画像サイズ一覧<i className="pl-10 text-24_24 mbx-icon mbx-icon-External_link-thin"></i>
                      </a>
                    </div>
                  </div>
                )}
              </div>
            }
          />
        </div>
      </Page.Wrapper>
    </div>
  );
}

export default DetailEdit;
