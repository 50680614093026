import { AxiosError } from 'axios';
import cn from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import { Graph } from '@/componentsDirect/Candidates/Detail/Graph';
import { MessageSent } from '@/componentsDirect/Candidates/Detail/MessageSent';
import { ModalContainer } from '@/componentsDirect/Candidates/Detail/ModalContainer';
import { CandidatesNotification } from '@/componentsDirect/Candidates/Detail/Notification';
import { DataLayerContext } from '@/componentsDirect/common/DataLayerProviderContainer';
import { AdminPage } from '@/componentsDirect/Layout/Page';
import { BaseButton } from '@/componentsDirect/Parts/Button/BaseButton';
import { ModalDefault } from '@/componentsDirect/Parts/Modal/Default';
import { TableContainer, TableHeader } from '@/componentsDirect/Parts/Table';
import { HeaderTitle } from '@/componentsDirect/Parts/Title/HeaderTitle';
import { BasicInfo } from '@/componentsDirect/Parts/User/Module/BasicInfo';
import { Motivation } from '@/componentsDirect/Parts/User/Module/Motivation';
import { UserStatusLabel } from '@/componentsDirect/Parts/User/Module/Status';
import { FORM_OPTIONS } from '@/definition/FORM_OPTIONS';
import { RootState } from '@/reduxAdmin';
import { notificationError, notificationSuccess, toggleLoading } from '@/reduxAdmin/modules/utils';
import { CandidateData } from '@/types/DataLayerType';
import {
  CareerHistory,
  DirectApi,
  DirectOfferPatchParam,
  DirectUserResponse,
  LastLogin,
  MyNavi,
  Offer,
  OfferPositionData,
  OfferRateEnum,
  OfferStatusEnum,
  ReactionPostParam,
  ReactionPostParamNotifyTypeEnum,
  ReactionsResponseAllOfReactions,
  ResponseError,
  Status,
  UserResumeInfo,
} from '@/utils/api-client';
import {
  getDirectOptionChildrenFromValue,
  getOptionChildrenFromValue,
} from '@/utils/optionValueToChildren';
import {
  convertLineBreak,
  dataStringSplitter,
  getAge,
  linkifyUrls,
  noSelectText,
} from '@/utils/string';
import { genCandidateData } from '@/utils/utils';

type TUser = {
  career_history: CareerHistory;
  user_info: UserResumeInfo;
  last_login: LastLogin[];
  registration_date: string;
  matchbox_id: string;
  mc: MyNavi;
  offers: Offer[];
};

type TOfferStatus = {
  status: OfferStatusEnum;
  rate: OfferRateEnum;
  updatedAt?: string;
  userName?: string;
};

/**
 * 候補者 詳細
 * @author Kanta Shimizu
 */
export const CandidatesDetail = (): React.ReactElement => {
  // ====================================================================================================
  //
  // const
  //
  // ====================================================================================================
  const dispatch = useDispatch();
  const params: { id: string } = useParams();
  const history = useHistory();
  const userSelf: DirectUserResponse | null = useSelector(
    (state: RootState) => state.direct_auth.userInfo
  );
  const columns = [
    {
      title: 'ポジション名（職種名）',
      className: 'whitespace-nowrap',
    },
    {
      title: 'ポジション詳細（業務内容詳細）',
    },
  ];

  // ====================================================================================================
  //
  // state
  //
  // ====================================================================================================
  const [reactionModal, setReactionModal] = useState<boolean>(false);
  const [actionModal, setActionModal] = useState<boolean>(false);
  const [doneModal, setDoneModal] = useState<boolean>(false);
  const [user, setUser] = useState<TUser>();
  const [userInfo, setUserInfo] = useState<any>();
  const [careerHistory, setCareerHistory] = useState<CareerHistory & Status>();
  const [reactions, setReactions] = useState<ReactionsResponseAllOfReactions[]>();
  const [action, setAction] = useState({
    value: '',
    text: '',
    offer_position: ''
  });
  const [position, setPosition] = useState<OfferPositionData>();
  const [pickUpPosition, setPickUpPosition] = useState<OfferPositionData>();
  const [status, setStatues] = useState<TOfferStatus>();
  const [fullSize, setFullSize] = useState<number>();
  const { push } = React.useContext(DataLayerContext);

  // ====================================================================================================
  //
  // methods
  //
  // ====================================================================================================
  const getFullSize = () => {
    setFullSize(960 / 2 - window.innerWidth / 2);
  };

  const genUserData = (data: any): CandidateData | undefined => {
    if (!data) return undefined;
    const user: UserResumeInfo = data.user_info;
    const lastLogin: LastLogin[] = data.last_login;
    const registration: string = data.registration_date;
    const mc: MyNavi = data.mc;
    return genCandidateData(
      user.matchbox_id,
      user,
      lastLogin[0],
      registration,
      mc,
      data.user_admin_offers,
      data.user_admin_reactions
    );
  };

  const getUser = async (id: string) => {
    const res = await new DirectApi().getDirectUserMatchboxId(id);
    const chRes = await new DirectApi().getDirectCareerHistory(id);
    if (res.data && chRes) {
      const data = res.data as TUser & Status;
      setUser(data);
      setUserInfo(res.data);
      setCareerHistory(chRes.data);
      const reactions = await new DirectApi().getDirectReactions(params.id);
      setReactions(reactions.data.reactions?.reverse());
      setPosition(reactions.data.position);
      setPickUpPosition(reactions.data.reaction_position);
    }
  };

  const dateFormat = (date: string) => {
    const year = dataStringSplitter(date)[0];
    const month = dataStringSplitter(date)[1];
    const dayTime = dataStringSplitter(date)[2].split(' ');
    return year + '年' + month + '月' + dayTime[0] + '日';
  };

  const dateFormatUpdated = (date: string) => {
    const year = dataStringSplitter(date)[0];
    const month = dataStringSplitter(date)[1];
    const dayTime = dataStringSplitter(date)[2].split(' ');
    return year + '年' + month + '月' + dayTime[0] + '日 ' + dayTime[1];
  };

  // ====================================================================================================
  //
  // useMemo
  //
  // ====================================================================================================
  // 最新のリアクションデータ
  const latestReaction = useMemo(() => {
    if (status?.updatedAt) {
      const date = dateFormatUpdated(status?.updatedAt);
      // 最終更新：萱沼守2023/10/24 11:23
      return `最終更新：${status.userName} ${date}`;
    } else {
      return '';
    }
  }, [status]);

  const candidate = useMemo<CandidateData | undefined>(() => {
    return genUserData(userInfo);
  }, [userInfo]);

  const profileItems = useMemo(() => {
    const items: string[] = [];
    if (!user) return items;
    const prefecture = getOptionChildrenFromValue('prefecture', user?.user_info.prefecture);
    const gender = user?.user_info.gender
      ? getOptionChildrenFromValue('gender', user?.user_info.gender)
      : getOptionChildrenFromValue('gender', 0);
    const age = getAge(
      user?.user_info.birth_year ? user?.user_info.birth_year : 0,
      user?.user_info.birth_month ? user?.user_info.birth_month : 0,
      user?.user_info.birth_day ? user?.user_info.birth_day : 0
    );
    const money = getOptionChildrenFromValue('yearly_income', user?.mc.mc_yearly_income);
    const situation = FORM_OPTIONS.situation.find(
      (s) => s.value === user?.mc.mc_situation?.toString()
    )?.children;
    if (prefecture) {
      const prefectureName = Number(user.user_info.prefecture) !== 48 ? `${prefecture}在住` : prefecture;
      items.push(prefectureName);
    }
    if (gender) items.push(gender);
    if (age) items.push(`${age}歳`);
    if (money) items.push(`現年収${money}`);
    if (situation) items.push(situation);
    return items;
  }, [user]);

  const jobInfoItems = useMemo<string[]>(() => {
    const items: string[] = [];
    if (!careerHistory) return items;
    const career = careerHistory.careers?.find((career) => !career.c_leave_flag);
    if (career?.c_company_name) items.push(`直近の職歴：${career?.c_company_name}`);
    if (career?.c_employment_status)
      items.push(
        getOptionChildrenFromValue('employment_contract_type', career?.c_employment_status)
      );
    return items;
  }, [careerHistory]);

  const myMessageSend = useMemo(() => {
    if (
      reactions?.filter((r) => r.user?.matchbox_id === userSelf?.user_info.matchbox_id).length === 0
    ) {
      return { disabled: false, text: 'じぶんの意見を投稿する' };
    } else {
      return { disabled: true, text: 'じぶんの意見を投稿済' };
    }
  }, [reactions]);

  const isManager = useMemo(() => {
    const userType = userSelf?.user_info.user_type;
    return userType === 'direct_manager' || userType === 'direct_hr';
  }, [userSelf]);

  const fullScreen = useMemo(() => {
    return {
      marginLeft: fullSize,
      marginRight: fullSize,
    };
  }, [fullSize]);

  /**
   * みんなの意見を元にアクションするモーダルのポジションの初期値
   */
  const initPosition = useMemo(() => {
    // positionがあれば優先
    if (position && position.id) return position?.id.toString();
    return pickUpPosition?.id?.toString();
  }, [position, pickUpPosition]);

  // ====================================================================================================
  //
  // useEffect
  //
  // ====================================================================================================
  useEffect(() => {
    (async () => {
      dispatch(toggleLoading(true));
      try {
        const res = await new DirectApi().getDirectOffers('99999', '0', '', '');
        const offer = res.data.offers?.find((offer) => {
          return offer.id === Number(params.id);
        });
        if (offer?.matchbox_id) {
          getUser(offer.matchbox_id);
        }
        if (offer?.status || offer?.status === 0) {
          console.log(offer);
          setStatues({
            status: offer?.status,
            rate: offer.rate ? offer.rate : (0 as OfferRateEnum),
            updatedAt: offer.updated_at,
            userName: offer.user?.name ? offer.user?.name : '',
          });
        }
      } finally {
        dispatch(toggleLoading(false));
      }
    })();
    getFullSize();
    window.addEventListener('resize', () => {
      getFullSize();
    });

    return () => {
      window.removeEventListener('resize', getFullSize);
    };
  }, []);

  useEffect(() => {
    if (candidate !== undefined)
      push({
        event: 'pageView',
        actionType: 'page_view',
        actionName: 'page_view',
        candidates: candidate ? [candidate] : [],
      });
  }, [candidate]);

  /**
   * 自分の意見を投稿
   * 実行はモーダルコンポーネントでしてるので、そこまでバケツリレーしてる
   * */
  const onReaction = async (value: string, text: string) => {
    if (!user?.user_info.matchbox_id) return;
    const param: ReactionPostParam = {
      content: text,
      status: Number(value),
      matchbox_id: user?.user_info.matchbox_id,
      offer_id: Number(params.id),
      notify_type: 'reaction' as ReactionPostParamNotifyTypeEnum,
    };

    push({
      event: 'postOpinion',
      actionType: 'post_opinion',
      actionName: getDirectOptionChildrenFromValue('reactionOptions', Number(value)),
      matchingDegree: getDirectOptionChildrenFromValue('offer_mw_status', status?.rate),
      candidates: candidate ? [candidate] : [],
    });

    dispatch(toggleLoading(true));
    try {
      await new DirectApi().postDirectReaction(param);
      if (user?.user_info.matchbox_id) {
        getUser(user?.user_info.matchbox_id?.toString());
      }
      setReactionModal(false);
      dispatch(notificationSuccess('意見を投稿しました'));
    } catch (error) {
      const e = error as AxiosError<ResponseError>;
      if (e.response) dispatch(notificationError(e.response.data.error_message));
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  /**
   * みんなの意見を元にアクションする
   * 実行はモーダルコンポーネントでしてるので、そこまでバケツリレーしてる
   * */
  const onAction = async (value: string, text: string, offer_position?: string) => {
    const param: DirectOfferPatchParam = {
      id: params.id,
      offer_position: offer_position ? Number(offer_position) : null,
      status: Number(value),
      comment: text,
    };
    if (Number(value) === 4) param.mw_status = 3;

    push({
      event: 'candidateSelection',
      actionType: 'candidate_selection',
      actionName: getDirectOptionChildrenFromValue('actionOptions', Number(value)),
      matchingDegree: getDirectOptionChildrenFromValue('offer_mw_status', status?.rate),
      candidates: candidate ? [candidate] : [],
    });

    dispatch(toggleLoading(true));
    try {
      await new DirectApi().patchDirectOffer(param);
      if (user?.user_info.matchbox_id) {
        getUser(user?.user_info.matchbox_id?.toString());
      }
      const offers = await new DirectApi().getDirectOffers('99999', '0', '', '');
      const offer = offers.data.offers?.find((o) => {
        return o.id === Number(params.id);
      });
      if (offer?.status)
        setStatues({
          status: offer?.status,
          rate: offer.rate ? offer.rate : (0 as OfferRateEnum),
          updatedAt: offer.updated_at,
          userName: offer.user?.name ? offer.user?.name : '',
        });
      if (Number(value) === 3 || Number(value) === 8) {
        setDoneModal(false);
      } else {
        setActionModal(false);
      }
      dispatch(notificationSuccess('結果を実行しました'));
    } catch (error) {
      const e = error as AxiosError<ResponseError>;
      if (e.response) dispatch(notificationError(e.response.data.error_message));
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  const onActionSet = (value: string, text: string, offer_position?: string) => {
    // 「一次面接オファー」「カジュアル面談オファー」であれば最終確認モーダルを表示させる
    if (Number(value) === 3 || Number(value) === 8) {
      setAction({
        value: value,
        text: text,
        offer_position: offer_position || ''
      });
      setActionModal(false);
      setDoneModal(true);
    } else {
      onAction(value, text, offer_position);
    }
  };

  // ====================================================================================================
  //
  // JSX
  //
  // ====================================================================================================
  return (
    <AdminPage
      disableDataLayerPush
      header={
        <>
          <HeaderTitle title="候補者管理" updated={latestReaction} />
          {status?.status ? <CandidatesNotification status={status?.status} /> : null}
        </>
      }
    >
      <>
        <BaseButton
          theme="link"
          iconName="Arrow_Left"
          size="s"
          href="/direct/candidates/"
          className="mt-16"
        >
          一覧へ戻る
        </BaseButton>
        <section className="flex items-center mt-28">
          <Motivation
            changeDate={
              user?.mc.mc_preferred_change_date
                ? (user?.mc.mc_preferred_change_date as 1 | 2 | 3 | 4 | 5 | 6)
                : 6
            }
          />
          <BasicInfo className="ml-16" items={profileItems as string[]} color="blue" size="m" />
          <div className="ml-16">
            {status && <UserStatusLabel status={status?.status} rate={status?.rate} direct />}
          </div>
        </section>
        <div className="mt-12 flex space-x-8 items-center">
          <h3 className="text-18_24 font-bold mkt_mask_items">
            {user?.matchbox_id}
            {user?.mc.mc_experienced_job &&
              user?.mc.mc_experienced_year &&
              `：${getOptionChildrenFromValue(
                'preferred_job_type',
                user?.mc.mc_experienced_job
              )} 経験${getOptionChildrenFromValue(
                'experienced_year',
                user?.mc.mc_experienced_year
              )}`}
          </h3>
        </div>
        <BasicInfo className="mt-6 mkt_mask_items" items={jobInfoItems} size="s" />
        <p className="mt-6 text-gray-600 text-10_12">
          <span>
            アカウント開設日：{user?.registration_date && dateFormat(user?.registration_date)}
          </span>
          <span className="ml-16">
            最終ログイン日：{user?.last_login[0] && dateFormat(user?.last_login[0].login_date)}
          </span>
        </p>
        <div className="w-224 mt-56">
          <BaseButton
            theme="secondary"
            size="s"
            className="w-full"
            onClick={() =>
              history.push({
                pathname: `/direct/users/${user?.matchbox_id}`,
                state: { referrer: 'candidates', offer_id: params.id },
              })
            }
          >
            求職者の詳細情報を見る
          </BaseButton>
        </div>
        <div>
          <h4 className="mt-40 mbx-typography--heading_4">この求職者に対するみんなの意見</h4>
          {reactions && <Graph className="mt-24" reactions={reactions} />}
          {reactions?.length !== 0 && (
            <section className="mt-16 pb-84">
              {reactions &&
                reactions.map((reaction, index) => {
                  return (
                    <div key={index}>
                      <MessageSent reaction={reaction} />
                    </div>
                  );
                })}
            </section>
          )}
        </div>

        <div>
          <h4 className="mt-40 mbx-typography--heading_4">
            この求職者をピックアップした時の紐付けポジション
          </h4>
          <section className="mt-16 pb-84">
            <TableContainer className="w-full">
              <TableHeader columns={columns}></TableHeader>
              <tbody>
                <tr className="text-left border-b border-gray-200">
                  {/* ボジション名 */}
                  <td className="pl-16 py-14 font-bold text-subhead_2">
                    {noSelectText(pickUpPosition?.name)}
                  </td>
                  {/* ポジション詳細 */}
                  <td
                    className="pl-16 py-14 text-subhead_2"
                    dangerouslySetInnerHTML={{
                      __html: convertLineBreak(linkifyUrls(pickUpPosition?.body)),
                    }}
                  ></td>
                </tr>
              </tbody>
            </TableContainer>
          </section>
        </div>

        <div>
          <h4 className="mt-40 mbx-typography--heading_4">
            この求職者に直近でアクションした時の紹介ポジション
          </h4>
          <section className="mt-16 pb-84">
            <TableContainer className="w-full">
              <TableHeader columns={columns}></TableHeader>
              <tbody>
                <tr className="text-left border-b border-gray-200">
                  {/* ボジション名 */}
                  <td className="pl-16 py-14 font-bold text-subhead_2">
                    {noSelectText(position?.name)}
                  </td>
                  {/* ポジション詳細 */}
                  <td
                    className="pl-16 py-14 text-subhead_2"
                    dangerouslySetInnerHTML={{
                      __html: convertLineBreak(linkifyUrls(position?.body)),
                    }}
                  ></td>
                </tr>
              </tbody>
            </TableContainer>
          </section>
        </div>

        <div className="sticky bottom-0 right-0 p-24 flex justify-end" style={fullScreen}>
          <section className="flex">
            <div className={isManager ? 'w-186' : 'w-256'}>
              <BaseButton
                theme="primary"
                size="m"
                disabled={myMessageSend.disabled}
                onClick={() => setReactionModal(true)}
                className={cn('w-full', { 'pointer-events-none': myMessageSend.disabled })}
              >
                {myMessageSend.text}
              </BaseButton>
            </div>
            {isManager && (
              <div className="w-256 ml-16">
                <BaseButton
                  theme="primary"
                  size="m"
                  className="w-full"
                  onClick={() => setActionModal(true)}
                >
                  みんなの意見を元にアクションする
                </BaseButton>
              </div>
            )}
          </section>
        </div>

        <ModalDefault
          isOpen={reactionModal}
          onClose={() => setReactionModal(false)}
          className="rounded-modalContainer"
          dataLayer={{
            event: 'pageView',
            actionType: 'page_view',
            actionName: 'modal_open',
            virtualPageName: 'post-opinion',
            candidates: candidate ? [candidate] : [],
          }}
        >
          <ModalContainer
            type="reaction"
            onClose={() => setReactionModal(false)}
            onSend={onReaction}
          />
        </ModalDefault>
        <ModalDefault
          isOpen={actionModal}
          onClose={() => setActionModal(false)}
          className="rounded-modalContainer"
          dataLayer={{
            event: 'pageView',
            actionType: 'page_view',
            actionName: 'modal_open',
            virtualPageName: 'action',
            candidates: candidate ? [candidate] : [],
          }}
        >
          <ModalContainer
            type="action"
            onClose={() => setActionModal(false)}
            onSend={onActionSet}
            status={status?.status}
            initPosition={initPosition}
          />
        </ModalDefault>
        <ModalDefault
          isOpen={doneModal}
          onClose={() => setDoneModal(false)}
          className="mbx-direct-users-detail-modal"
        >
          <div className="w-624 py-80">
            {Number(action.value) === 3 &&
              <p className="text-14_21">「一次面接オファーする」のアクションは候補者に通知されますが、よろしいですか？</p>
            }
            {Number(action.value) === 8 &&
              <p className="text-14_21">「カジュアル面談オファーする」のアクションは候補者に通知されますが、よろしいですか？</p>
            }
            <div className="flex justify-between space-x-16 mt-24 px-140">
              <div className="w-224">
                <div className="w-full base-button" onClick={() => setDoneModal(false)}>
                  <div className="button size-m theme-secondary flex items-center cursor-pointer">
                    <span className="font-bold label">キャンセル</span>
                  </div>
                </div>
              </div>
              <div className="w-224">
                <div className="w-full base-button" onClick={() => onAction(action.value, action.text, action.offer_position)}>
                  <div className="button size-m theme-primary flex items-center cursor-pointer">
                    <span className="font-bold label">投稿する</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalDefault>
      </>
    </AdminPage>
  );
};
