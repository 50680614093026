import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router';
import cn from 'classnames';

import { TDetailProps } from '@/pagesDirect/OfferPositions/Index';
import { BaseButton, ButtonIconButton } from '@/componentsDirect/Parts/Button/';
import { FormToggleButton } from '@/componentsDirect/Parts/Form/';
import { useOfferPositions } from '@/hooksDirect/useOfferPositions';
import { convertLineBreak, linkifyUrls, noDataText } from '@/utils/string';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

type TProps = {
  data: {
    id: string;
    name: string;
    body: string;
    active: boolean;
  };
  onDelete: (id: string) => boolean;
  detailState: TDetailProps | undefined
  setDetailStates: React.Dispatch<React.SetStateAction<TDetailProps[]>>;
};

/**
 * ポジションリストデータ
 * TableContainerと共に利用する
 * https://www.figma.com/file/U3CKCcmEc7eIjxkbYoCZx7/UI?type=design&node-id=11781-162244&mode=dev
 */
export const TableBodyOfferPositionList = ({ data, onDelete, detailState, setDetailStates }: TProps): React.ReactElement => {
  // ====================================================================================================
  //
  // const
  //
  // ====================================================================================================
  const history = useHistory();
  const positionRef = useRef<HTMLParagraphElement>(null);
  const displayRef = useRef<HTMLParagraphElement>(null);
  const isFirstRun = useRef(true);
  const [reactiveData, setReactiveData] = useState(data);
  const { patchOfferPositionId } = useOfferPositions();
  const { listeners, setNodeRef, transform, transition } = useSortable({ id: data.id });
  const style = {
    transform: CSS.Transform.toString({
      x: transform?.x || 0,
      y: transform?.y || 0,
      scaleX: 1,
      scaleY: 1
    }),
    transition,
  };

  // ====================================================================================================
  //
  // method
  //
  // ====================================================================================================
  const onClick = () => {
    history.push({
      pathname: `/direct/offer_positions/${data.id}`,
    });
  };

  /**
   * 選択表示の切り替え
   * */
  const onClickToggleButton = () => {
    const patchData = {
      name: reactiveData.name,
      body: reactiveData.body,
      active: !reactiveData.active,
    };
    patchOfferPositionId(data.id + '', patchData);
    setReactiveData({
      id: reactiveData.id,
      name: reactiveData.name,
      body: reactiveData.body,
      active: !reactiveData.active,
    });
  };

  /**
   * ポジション詳細の表示の切り替え
   * */
  const onDisplay = () => {
    setDetailStates((prev) => {
      if (detailState) {
        const newStates = prev.map((prevState) => {
          const isSwitch = detailState.id === prevState.id;
          return {
            id: prevState.id,
            allDisplay: isSwitch ? !prevState.allDisplay : prevState.allDisplay,
            isDisplayText: prevState.isDisplayText
          }
        });
        return newStates;
      }
      return prev;
    });
  }

  // ====================================================================================================
  //
  // useEffect
  //
  // ====================================================================================================
  useEffect(() => {
    setReactiveData(data);

    if (positionRef.current && displayRef.current) {
      // ポジション詳細のベースの高さ
      const detailBaseHeight = 126;
      const { clientHeight } = positionRef.current;
      const displayRefStyle = displayRef.current.style;
      // 初回のみ実行
      if (clientHeight > detailBaseHeight && isFirstRun.current) {
        // ポジション詳細が指定の高さ以上であれば制限する
        setDetailStates((prev) => {
          if (detailState) {
            const newStates = prev.map((prevState) => {
              const isSwitch = detailState.id === prevState.id;
              return {
                id: prevState.id,
                allDisplay: isSwitch ? false : prevState.allDisplay,
                isDisplayText: isSwitch ? true :  prevState.isDisplayText
              }
            });
            return newStates;
          }
          return prev;
        });
        displayRefStyle.display = 'block';
      }
      if (isFirstRun.current) {
        isFirstRun.current = false;
        return;
      }
      // 2回目以降実行
      displayRefStyle.display = detailState?.isDisplayText ? 'block' : 'none';
    }
  }, [data]);

  // ====================================================================================================
  //
  // jsx
  //
  // ====================================================================================================
  return (
    <tr className="text-left border-b border-gray-200" ref={setNodeRef} style={style}>
      {/* ボジション名 */}
      <td className="align-top pl-16 py-14 font-bold text-subhead_2 whitespace-pre-wrap w-200">
        {noDataText(data.name)}
      </td>
      {/* ポジション詳細 */}
      <td
        className="pl-16 py-14 text-subhead_2 pr-16"
      >
        <p
          dangerouslySetInnerHTML={{
            __html: convertLineBreak(noDataText(linkifyUrls(data.body)) as string),
          }}
          className={cn('overflow-hidden', detailState?.allDisplay ? 'h-auto' : 'h-126')}
          ref={positionRef}
        ></p>
        <p
          className="mbx-button-textLink cursor-pointer pt-2 w-fit hidden"
          ref={displayRef}
          onClick={onDisplay}
        >
          {detailState?.allDisplay ? "...もっと少なく表示する" : "...もっと表示する"}
        </p>
      </td>

      {/* 削除機能 */}
      <td className="text-right flex py-14">
        <div className="w-84">
          <BaseButton size="s" theme="secondary" className="w-full" onClick={onClick}>
            編集
          </BaseButton>
        </div>
        <FormToggleButton
          className="mbx-list_item__toggle"
          label="選択表示"
          checked={reactiveData.active}
          onClick={() => onClickToggleButton()}
        />
        <span className="dragHandle" {...listeners} data-cypress="draggable-handle">
          <ButtonIconButton type="gray" iconName="Sortable" />
        </span>
        <ButtonIconButton
          type="gray"
          iconName="Clear"
          onClick={(e) => {
            e.preventDefault();
            if (data.id) onDelete(data.id + '');
          }}
        />
      </td>
    </tr>
  );
};
