import React, { useState, useEffect } from 'react';
import { eventList } from '@/definition/EVENT_LIST';
import { State } from '@/redux/state';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

export const Ed138 = (): React.ReactElement => {
  const history = useHistory();
  const isAuth = useSelector((state: State) => state.isAuth);
  const [isLogin, setIsLogin] = useState(false);
  const [deadline, setDeadline] = useState(false);

  useEffect(() => {
    if (isAuth) {
      setIsLogin(true);
    } else {
      setIsLogin(false);
    }
  }, [isAuth]);

  useEffect(() => {
    const e = eventList.find(e => e.id === "0138");
    if (!e) return;
    const yyyymmdd = e.deadlineDate.split(' ')[0];
    const deadlineDate = new Date(`${yyyymmdd.replace(/-/g, "/")} 12:00:00`);
    const now = new Date;
    if (now > deadlineDate) setDeadline(true);
  }, []);

  const ctaClick = () => {
    const path = `${location.pathname}#redirect_entry`;
    localStorage.setItem('setEventAfterLogin', path);
    history.push('/entry');
  }

  const style = {
    img: 'border-gray-300 border-r border-b border-l'
  };
  return (
    <div className="event-contents">
      <div className="event-detail">
        <div className="md:-mx-16 sm:-mx-16">
          <img className={style.img} src="../images/Top/event/event_0138_mv.png" width="978" height="504" alt="" />
        </div>
        <h1 className="mt-56 sm:mt-30 text-29_46 sm:text-18_24 font-bold text-center">3/25（火）19:30 -<br />
        デザイナー・ディレクター必見！<br />SEOの基本と実例</h1>
        {deadline &&
          <section className="mt-30 sm:mt-24 mb-40 sm:mb-18">
            <div className="flex flex-col items-center justify-center px-16 py-25 sm:p-16 bg-gray-100">
              <p className="mb-10 lg:mb-16 text-18_33 sm:text-14_21 font-bold">このセミナーの受付は終了しています。</p>
              <p className="text-16_28 sm:text-14_21">定期的にセミナーを開催しておりますので、<br className="sm:hidden" />最新の開催情報は<a className="textLink" href="../event/">こちら</a>よりご確認ください。</p>
            </div>
          </section>
        }
        <section className='mt-80 sm:mt-55'>
          < h3 className='text-18_24 sm:text-14_21 font-bold'>セミナーについて</h3>
          <div className="mt-30 sm:mt-16 mb-30 sm:mb-18 box-line">
            <img src="../images/Top/event/event_0049_image.jpg" width="980" height="374" alt="" />
          </div>
          <p className="text-16_28 sm:text-14_21 mt-30">
            日頃マッチボックスユーザーの皆さまに向けた無料セミナーを開催するにあたり、現在デザイナー・またはディレクターとして活躍されている方からコンテンツ作成に付随するSEO領域について関心があるということをご意見として多くいただくようになりました。<br />
            <br />
            今回は、デザイナー・ディレクターに必要なSEOの基本的な知識について実例を交えてお話しいたします。SEOに強いサイト構造の考え方や、キーワード選定、ライティングやフロントエンドの側面から等、デザイナー、ディレクター各領域からの目線で知っておいてほしい知識をお伝えします。<br />
            <br />
            登壇するのは現在株式会社マイナビワークスで各サービスのサイト運営・SEO対策を行っている本郷孝太郎です。<br />
            質疑応答の時間もございますので、実務でのSEO領域についてのお悩みを解決する機会としてぜひご参加ください。
          </p>
        </section>
        {!deadline && !isLogin &&
          <section className='mt-80 sm:mt-55 mb-40 sm:mb-18'>
            <div className='flex flex-col items-center justify-center'>
              <p className='mb-6 md:mb-12 lg:mb-12 text-16_28 sm:text-14_21'>MATCHBOXのアカウントをお持ちでない方</p>
              <p className='base-button mb-15 md:mb-24 lg:mb-24 text-16_28 sm:text-14_21'>
                <p className='button size-m theme-secondary flex items-center flex-col font-bold cursor-pointer' onClick={ctaClick}>
                  アカウントを新規で作成する
                  <span className='text-14_21 sm:text-12_20 text-center'>※セミナー参加にはMATCHBOXの事前登録が必要です。</span>
                </p>
              </p>
              <p className='text-16_28 sm:text-14_21'>アカウントをお持ちの方は<Link className='textLink font-bold' to="/login">こちら</Link>からログイン後、お申込みください。</p>
            </div>
          </section>
        }
        <section>
          <h2 className='text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6'>以下に該当する方にオススメです。</h2>
          <div className="mt-30 sm:mt-16 p-40 sm:p-24 box-line text-left">
            <ul className="text-16_28 sm:text-14_21">
              <li className="flex mb-4">
                <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>関わっているコンテンツ制作業務やプランニングといった実務の中で、SEOの基本的な知識が足りていないと悩んでいる</span>
              </li>
              <li className="flex mb-4">
                <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>デザイナー・ディレクターとして携わっているコンテンツ制作に関してクオリティのアップをはかりたい</span>
              </li>
              <li className="flex mb-4">
                <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>SEOに強いサイトにするため設計に関する基本的な知識を知りたい</span>
              </li>
              <li className="flex">
                <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>セミナーを踏まえて転職・書類作成の相談をしたい方</span>
              </li>
            </ul>
          </div>
        </section>
        {!deadline && !isLogin &&
          <section className='mt-80 sm:mt-55 mb-40 sm:mb-18'>
            <div className='flex flex-col items-center justify-center'>
              <p className='mb-6 md:mb-12 lg:mb-12 text-16_28 sm:text-14_21'>MATCHBOXのアカウントをお持ちでない方</p>
              <p className='base-button mb-15 md:mb-24 lg:mb-24 text-16_28 sm:text-14_21'>
                <p className='button size-m theme-secondary flex items-center flex-col font-bold cursor-pointer' onClick={ctaClick}>
                  アカウントを新規で作成する
                  <span className='text-14_21 sm:text-12_20 text-center'>※セミナー参加にはMATCHBOXの事前登録が必要です。</span>
                </p>
              </p>
              <p className='text-16_28 sm:text-14_21'>アカウントをお持ちの方は<Link className='textLink font-bold' to="/login">こちら</Link>からログイン後、お申込みください。</p>
            </div>
          </section>
        }
        <section>
          <h2 className='text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6'>講師プロフィール</h2>
          <div className='event-instructor box-line mb-30 sm:p-16'>
            <div className="instructor-photo">
              <img src="../images/Top/event/event_0123_prof-02.jpg" alt="" />
            </div>
            <dl className="instructor-prof sm:mt-16">
              <dt className="instructor-name text-16_28 font-bold">
                本郷 孝太郎（KOTARO HONGO）
              </dt>
              <dd className="instructor-position text-16_28 sm:text-14_21 mb-18 sm:mb-12">
                株式会社マイナビワークス マーケティング統括部
              </dd>
              <dd className="instructor-career text-16_28 sm:text-14_21">
              大学で経済学を修了後、金融系アセットマネジメント会社に入社、対法人営業、対個人営業、マーケティング業務を経験。<br />
              その後、マイナビワークスにマーケターとしてジョイン。入社初年度でアフィリエイト経由でのセッション数約380％増を達成。また、社内エンゲージメントプラットフォーム運営プロジェクトのリーダーを拝名し、プラットフォームの社内定着率を10％未満から94％にまで向上させた。現在は、主に、Web/IT/ゲーム業界を専門とした人材紹介サービス『マイナビクリエイター』にて、サイト運営、SEO対策など幅広く担当。2023年10月から2024年3月においてorganic経由のsession数、約140％増を達成する。趣味でyoutubeやってます。
              </dd>
            </dl>
          </div>
          <div className='event-instructor box-line sm:p-16'>
            <div className="instructor-photo">
              <img src="../images/Top/event/event_0123_prof-01.jpg" alt="" />
            </div>
            <dl className="instructor-prof sm:mt-16">
              <dt className="instructor-name text-16_28 font-bold">
                伊東 宏之（HIROYUKI ITO）
              </dt>
              <dd className="instructor-position text-16_28 sm:text-14_21 mb-18 sm:mb-12">
                シララ株式会社 代表取締役<br />
                <a className="textLink" href='https://sirara.co.jp/' target={'_blank'} rel="noreferrer">https://sirara.co.jp/<i className="mbx-icon mbx-icon-External_link"></i></a>
              </dd>
              <dd className="instructor-career text-16_28 sm:text-14_21">
                1000ページ規模のECサイトのSEOや、医療機関・地域事業者向けのMEO施策など、Webディレクターとして多岐にわたるクライアントを支援。
              </dd>
            </dl>
          </div>
        </section>
        <section>
          <h2 className="text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6">当日スケジュール</h2>
          <table className="index timetable text-16_28 sm:text-14_21 mb-30 sm:mb-12">
            <tbody>
              <tr>
                <th scope="row" className="bg-gray-50 font-normal whitespace-nowrap">
                  <span className="sm:block">19：30</span><span className="sm:block">～</span><span className="sm:block">21：00</span>
                </th>
                <td>
                  <ul className="text-16_28 sm:text-14_21">
                    <li className="flex"><span>・</span><span>自己紹介</span></li>
                    <li className="flex"><span>・</span><span>基本的なSEOに強いサイト構造</span></li>
                    <li className="flex"><span>・</span><span>ペルソナ設計の重要性</span></li>
                    <li className="flex"><span>・</span><span>【ディレクター向け】ちょっとマーケ寄りSEOノウハウ（実例紹介）</span></li>
                    <li className="flex"><span>・</span><span>【デザイナー向け】記事内のデザインに対するポイント（フロントエンドSEO）</span></li>
                    <li className="flex"><span>・</span><span>まとめ</span></li>
                    <li className="flex"><span>・</span><span>質疑応答</span></li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        {!deadline && !isLogin &&
          <section className='mt-80 sm:mt-55 mb-40 sm:mb-18'>
            <div className='flex flex-col items-center justify-center'>
              <p className='mb-6 md:mb-12 lg:mb-12 text-16_28 sm:text-14_21'>MATCHBOXのアカウントをお持ちでない方</p>
              <p className='base-button mb-15 md:mb-24 lg:mb-24 text-16_28 sm:text-14_21'>
                <p className='button size-m theme-secondary flex items-center flex-col font-bold cursor-pointer' onClick={ctaClick}>
                  アカウントを新規で作成する
                  <span className='text-14_21 sm:text-12_20 text-center'>※セミナー参加にはMATCHBOXの事前登録が必要です。</span>
                </p>
              </p>
              <p className='text-16_28 sm:text-14_21'>アカウントをお持ちの方は<Link className='textLink font-bold' to="/login">こちら</Link>からログイン後、お申込みください。</p>
            </div>
          </section>
        }
        <section className="mb-64 sm:mb-48">
          <h2 className="text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 sm:mb-8 sm:pb-6 text-left">開催概要</h2>
          <table className="summary text-16_28 sm:text-14_21">
            <tbody>
            <tr>
                <th scope="row" className="font-normal text-left">開催日時</th>
                <td>2025/3/25（火）19:30～<br />
                  [入室開始] 19:20～</td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">開催方法</th>
                <td>Zoom開催<br />参加者の皆さまには別途URLをお送りします。</td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">参加費</th>
                <td>無料</td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">応募手順</th>
                <td>
                  <dl>
                    <dt className="mb-18 sm:mb-8">1.参加申し込み</dt>
                    <dd className="mb-38 sm:mb-16">MATCHBOXへログインいただき、お申し込みください。（アカウント作成は<a className="textLink"
                      href="https://matchbox.work/entry">こちら</a>から）</dd>
                    <dt className="mb-18 sm:mb-8">2.ご連絡</dt>
                    <dd className="mb-38 sm:mb-16">マッチボックス セミナー事務局より、MATCHBOXに登録していただいているメールアドレス宛に当日の詳細のご連絡差し上げます。</dd>
                    <dt className="mb-18 sm:mb-8">3.開催当日</dt>
                    <dd>当日はZoomにて開催いたします。</dd>
                  </dl>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        {!deadline && !isLogin &&
          <section className='mt-80 sm:mt-55 mb-40 sm:mb-18'>
            <div className='flex flex-col items-center justify-center'>
              <p className='mb-6 md:mb-12 lg:mb-12 text-16_28 sm:text-14_21'>MATCHBOXのアカウントをお持ちでない方</p>
              <p className='base-button mb-15 md:mb-24 lg:mb-24 text-16_28 sm:text-14_21'>
                <p className='button size-m theme-secondary flex items-center flex-col font-bold cursor-pointer' onClick={ctaClick}>
                  アカウントを新規で作成する
                  <span className='text-14_21 sm:text-12_20 text-center'>※セミナー参加にはMATCHBOXの事前登録が必要です。</span>
                </p>
              </p>
              <p className='text-16_28 sm:text-14_21'>アカウントをお持ちの方は<Link className='textLink font-bold' to="/login">こちら</Link>からログイン後、お申込みください。</p>
            </div>
          </section>
        }
        <section className='mt-80 sm:mt-55'>
          <h3 className='text-18_24 sm:text-14_21 font-bold'>開催について</h3>
          < p className='text-16_28 sm:text-14_21 mt-30 sm:mt-16'>
            Web・IT業界で必要不可欠な存在であるWebディレクター・Webデザイナーの皆さまを支援するために、日本ディレクション協会で受講者累計2, 000名を超える「0からのWebディレクション講座」を生み出したメンバーでもある一般社団法人ディレクションサポート協会 会長の助田正樹氏と連携して、Webの現場最前線で働くロールモデルの方々をゲストとしてお呼びし、キャリアを考えるために有益な情報提供を開始しています。          </p >
        </section >
        <section className="mt-80 sm:mt-55 p-40 sm:p-24 bg-gray-50 text-left">
          <h3 className="text-18_24 sm:text-14_21 font-bold">注意点</h3>
          <ul className="mt-16 text-16_28 sm:text-14_21">
            <li className="flex"><span>・</span><span>セミナー開催用のZoomURLはセミナー前日・当日にメールにてお送りします。</span></li>
            <li className="flex"><span>・</span><span>セミナー開催用のZoomURLをお知らせするメールはマッチボックスにご登録いただいているメールアドレス（ログインID）に送付しております。</span></li>
            <li className="flex"><span>・</span><span>受信フォルダ・迷惑メール設定、フォルダの受信容量やドメインの許可（@matchbox.work）の確認をお願いいたします。</span></li>
            <li className="flex"><span>・</span><span>マッチボックス登録時のアドレス、またはセミナー申込み時に入力したアドレスにURLへ届いていない場合は<a className='textLink' href='https://survey.mynavi.jp/cre/?enq=2va0sJC992s%3d' target={'_blank'} rel="noreferrer">こちら<i className="mbx-icon mbx-icon-External_link"></i></a>からお問い合わせください。</span></li>
          </ul>
        </section>
        <p className="text-center mt-120 sm:mt-55">
          <a className="textlink_back text-16_16 sm:text-14_14" href="./"><i className="mbx-icon mbx-icon-Arrow_Right"></i><span className="label pl-16">イベント一覧ページに戻る</span></a>
        </p>
      </div>
    </div>
  );
};
