import '@/styles/pages/Portfolio/TopEdit.scss';

import arrayMove from 'array-move';
import { AxiosError } from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import cn from 'classnames';
import { cloneDeep } from 'lodash';

import { BaseButton } from '@/components/common/Button/';
import { DataLayerContext } from '@/components/common/DataLayerProviderContainer';
import { CropperModal } from '@/components/common/Modal/Cropper';
import * as Page from '@/components/common/Page';
import {
  HeaderImage,
  PortfolioHeaderEdit,
  PortfolioNavigation,
} from '@/components/common/Portfolio/';
import { SpEditModal } from '@/components/common/Portfolio/Edit/SpEditModal';
import { WorkAddModal } from '@/components/common/Portfolio/Edit/WorkAddModal';
import { WorkListSection } from '@/components/common/Portfolio/Edit/WorkListSection';
import { WorksRemoveModal } from '@/components/common/Portfolio/Edit/WorkRemoveModal';
import { WorkTypeSelectModal } from '@/components/common/Portfolio/Edit/WorkTypeSelectModal';
import { WorksDoneModal } from '@/components/common/Portfolio/Edit/WorksDoneModal';
import HeaderTitle from '@/components/common/Title/HeaderTitle';
import { ButtonTextLink } from '@/components/common/Button/TextLink';
import { IMAGE_SIZE } from '@/definition/IMAGE_SIZE';
import { usePagePortfolio } from '@/hooks/usePagePortfolio';
import { useFileLimit } from '@/hooks/useFIleLimit';
import { useMBXMediaQuery } from '@/hooks/useMBXMediaQuery';
import {
  notificationError,
  notificationSuccess,
  setDataLayer,
  setDataLayerChangedBy,
  toggleLoading,
  setPortfolio,
  setOldPortfolio,
} from '@/redux';
import { State } from '@/redux/state';
import {
  Portfolio,
  PortfolioApi,
  PortfolioItem,
  PortfolioItemPostParam,
  ResponseError,
  UserInfo,
  Status
} from '@/utils/api-client';
import { progressPercent } from '@/utils/utils';

function TopEdit(): React.ReactElement {
  // 公開作品の上限は15作品
  const limitVisibleItems = 15;
  const [removeModal, setRemoveModal] = useState<boolean>(false);
  const [removePortfolioIndex, setRemovePortfolioIndex] = useState<number | null>(null);
  const [portfolioTypeModal, setPortfolioTypeModal] = useState<boolean>(false);
  const [portfolioAddModal, setPortfolioAddModal] = useState<boolean>(false);
  const [doneModal, setDoneModal] = useState<boolean>(false);
  const [selectedTypeContents, setSelectedTypeContents] = useState<string[]>(['', '']);
  const [portFolioItems, setPortFolioItems] = useState<PortfolioItem[]>();
  const [onMove, setOnMove] = useState<boolean>(false);
  const [spModal, setSpModal] = useState<boolean>(false);
  const [pending, setPending] = useState<boolean>(false);
  const user: UserInfo | null = useSelector((state: State) => state.user);
  const history = useHistory();
  const dispatch = useDispatch();
  const mq = useMBXMediaQuery();
  const { portfolio: portFolio, oldPortfolio, patchPortfolio, setUserUpdate, setBannerImageUpdate, setBeforeUnload } = usePagePortfolio();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [buffer, setBuffer] = useState<string | ArrayBuffer | null>(null);
  const { checkFileSize } = useFileLimit();
  const { push } = useContext(DataLayerContext);

  useEffect(() => {
    setPending(false);
    if (!user?.user_id || !portFolio) return;
    const items = portFolio.items || [];
    setPortFolioItems(Array.from(items?.values()));
    setPending(true);
  }, [user?.user_id, portFolio]);

  useEffect(() => {
    if (!onMove) return;
    if (!portFolio?.items) return;
    const copy = Object.assign(portFolio);
    const newPortfolio = {...copy, items: portFolioItems as any} as (Portfolio & Status);
    dispatch(setPortfolio(newPortfolio));
    setOnMove(false);
  }, [onMove]);

  //ドラック&ドロップ
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDragEnd = ({ active, over }: any) => {
    if (active.id !== over.id && portFolioItems) {
      const oldIndex = Array.from(portFolioItems.values())
        .map((item) => item.i_id)
        .indexOf(parseInt(active.id));
      const newIndex = Array.from(portFolioItems.values())
        .map((item) => item.i_id)
        .indexOf(parseInt(over.id));
      setPortFolioItems((itemsArray) => {
        if (!itemsArray) return;
        return arrayMove(itemsArray, oldIndex || 0, newIndex || 0);
      });
      setOnMove(true);
    }
  };

  //削除モーダルOPEN
  const removeModalOpen = (index: number) => {
    setRemovePortfolioIndex(index);
    setRemoveModal(true);
  };

  //カード削除
  const removePortfolio = async () => {
    if (removePortfolioIndex === null) return;
    if (!portFolioItems) return;
    dispatch(toggleLoading(true));
    const i_id: number = Array.from(portFolioItems.values())[removePortfolioIndex].i_id;
    try {
      await new PortfolioApi().deletePortfolioItem(i_id.toString());
      const newItems = Array.from(portFolioItems.values()).filter((item) => item.i_id !== i_id);
      const newPortfolio = {...portFolio, items: newItems as any} as (Portfolio & Status);
      dispatch(setPortfolio(newPortfolio));
      if (oldPortfolio?.items) {
        const newOldItems = Array.from(oldPortfolio.items.values()).filter((item) => item.i_id !== i_id);
        const newOldPortfolio = {...oldPortfolio, items: newOldItems as any} as (Portfolio & Status);
        dispatch(setOldPortfolio(newOldPortfolio));
      }
      setRemovePortfolioIndex(null);
      setRemoveModal(false);
      dispatch(notificationSuccess('作品を削除しました'));
    } catch (error) {
      const e = error as AxiosError<ResponseError>;
      if (e.response) dispatch(notificationError(e.response.data.error_message));
    } finally {
      dispatch(toggleLoading(false));
    }
  };
  // 追加する作品選択 画像
  const selectedTypeImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setPortfolioTypeModal(false);
    if (!e.target.files) return;
    const imageFile = e.target.files[0];
    if (!checkFileSize(imageFile.size, 5)) return;
    setIsOpen(true);
    const reader = new FileReader();

    reader.onload = () => {
      setBuffer(reader.result);
    };

    // 画像の読み込み
    reader.readAsDataURL(imageFile);
  };

  const onCropped = async (imageFile: File) => {
    if (!oldPortfolio || !portFolioItems || !user?.user_id) return;
    dispatch(toggleLoading(true));
    try {
      const uploadedFile = await new PortfolioApi().postFiles(imageFile);
      push({
        event: 'fileUpload',
        actionType: 'file_upload',
        actionName: '画像',
      });
      const copyPortfolio: (Portfolio & Status) = { ...oldPortfolio };
      const copyItems = Object.assign([...portFolioItems]);
      const iVisibleFlag = copyItems.filter((item: PortfolioItem) => item.i_visible == true).length < limitVisibleItems ? 1 : 0;
      const newItem = {
        i_description: '',
        i_pages: [],
        i_visible: iVisibleFlag,
        i_start_date: null,
        i_image: {
          f_id: uploadedFile.data.f_id,
          f_url: uploadedFile.data.f_url,
          f_thumbnail: uploadedFile.data.f_thumbnail,
        },
        i_end_date: null,
        i_title: '',
        i_tags: [],
        i_id: null,
        i_embedlink: null,
        i_url: '',
      };
      copyItems.push(newItem);
      copyPortfolio.items = copyItems;
      const id = user?.user_id;
      if (!id) return;

      const res = await new PortfolioApi().patchPortfolioEdit(user.user_id, copyPortfolio);
      const data = res.data;
      dispatch(setOldPortfolio(data));
      const resItems = (data?.items) ? Array.from(data.items.values()) : [];
      const resNewItem = resItems[resItems.length - 1];

      const copy = cloneDeep(portFolio);
      const newItems = (copy?.items) ? [...Array.from(copy.items.values()), resNewItem] : [resNewItem];
      const newPortfolio = {...copy, items: newItems as any} as (Portfolio & Status);
      dispatch(setPortfolio(newPortfolio));
      // TODO: PATCH後に新しいPortfolioItem[]が返ってこないので自動遷移を停止する
      // const res = await refresh();
      // if (res) {
      //   const i_id = Array.from(res.items.values())[Array.from(res.items.values()).length - 1]
      //     .i_id;
      //   linkTo(i_id);
      // }
    } catch (error) {
      const e = error as AxiosError<ResponseError>;
      if (e.response) dispatch(notificationError(e.response.data.error_message));
    } finally {
      setPortfolioTypeModal(false);
      dispatch(toggleLoading(false));
      setIsOpen(false);
      setDoneModal(true);
    }
  };

  const linkTo = (id: number) => {
    const user_id = user?.user_id;
    if (!user_id) return;
    history.push(`/mypage/portfolio/${user_id}/${id}/edit`);
  };

  //i_visible更新
  const onVisible = (id: number) => {
    if (!portFolio) return;
    setBeforeUnload(true);
    const items = cloneDeep(portFolio);
    if (!items?.items) return;
    Array.from(items?.items.values()).map((item: PortfolioItem) => {
      if (item.i_id === id) {
        item.i_visible = !item.i_visible;
      }
    });
    dispatch(setPortfolio(items));
  };

  const itemAdd = () => {
    if (!portFolioItems) return;
    const iVisibleItems = portFolioItems.filter((item: PortfolioItem) => item.i_visible == true);
    if (iVisibleItems.length > limitVisibleItems) {
      dispatch(notificationError('公開作品の上限に達しました、作品を非表示にするか削除して追加してください。'));
    } else {
      setPortfolioTypeModal(true);
    }
  }

  //作品タイプ選択に戻る
  const returnModal = () => {
    setPortfolioAddModal(false);
    setPortfolioTypeModal(true);
  };

  // 作品POST
  const postItemApi = async (url: string) => {
    dispatch(toggleLoading(true));
    // 追加するURL
    const postData: PortfolioItemPostParam = {
      url: url,
    };

    try {
      const res: any = await new PortfolioApi().postPortfolioItem(postData);
      const data = res.data as (Portfolio & Status);
      dispatch(setOldPortfolio(data));
      const resItems = (data?.items) ? Array.from(data.items.values()) : [];
      const resNewItem = resItems[resItems.length - 1];

      const copy = cloneDeep(portFolio);
      const newItems = (copy?.items) ? [...Array.from(copy.items.values()), resNewItem] : [resNewItem];
      const newPortfolio = {...copy, items: newItems as any} as (Portfolio & Status);
      dispatch(setPortfolio(newPortfolio));

      dispatch(setDataLayerChangedBy('top_edit'));
      const items = newPortfolio?.items ? Array.from(newPortfolio.items.values()) : [];
      const progress = [];
      for (const item of items) {
        const user_id = user?.user_id;
        if (user_id) {
          const res = await new PortfolioApi().getPortfolioProgressDetail(user_id, `${item.i_id}`);
          progress.push(progressPercent(res.data.progress));
        }
      }
      dispatch(
        setDataLayer({
          portfolioItemNum: `${items.length}`,
          portfolioCompleteness: `${progress?.join('|')}`,
        })
      );

      // TODO: POST後に新しいPortfolioItem[]が返ってこないので自動遷移を停止する
      // const res = await refresh();
      // if (res) {
      //   const i_id = Array.from(res.items.values())[Array.from(res.items.values()).length - 1].i_id;
      //   linkTo(i_id);
      // }
      setPortfolioAddModal(false);
      setDoneModal(true);
    } catch (error) {
      const e = error as AxiosError<ResponseError>;
      if (e.response) dispatch(notificationError(e.response.data.error_message));
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  const onSubmit = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    await patchPortfolio();
    const button = e.target as HTMLDivElement;
    if (button.textContent === "反映を確認する" && user) history.push(`/mypage/portfolio/${user.user_id}`);
  }

  const style = portFolio?.updated_at ? { justifyContent: 'space-between' } : undefined;

  return (
    <div className="portfolio-edit">
      <Page.Wrapper
        header={
          <>
            <div className="sticky top-0 z-10">
              <HeaderTitle title="ポートフォリオの編集">
                <div className="portfolio-edit-preview-wrapper" style={style}>
                  {portFolio?.updated_at && (
                    <p className="text-gray-700 text-10_10">最終更新：{portFolio?.updated_at}</p>
                  )}
                  {user && (
                    <div className="flex justify-end sm:-mr-16 sp_none">
                      <BaseButton theme="secondary" size="40" width="large" className="pr-10" onClick={onSubmit}>
                        保存する
                      </BaseButton>
                      <BaseButton theme="primary" size="40" width="large" onClick={onSubmit}>
                        反映を確認する
                      </BaseButton>
                    </div>
                  )}
                </div>
              </HeaderTitle>
              {user && (
                <div className="sp_only">
                  <div className="pr-16 bg-gray-200 border-b border-gray-300 h-48 flex items-center	justify-end">
                    <BaseButton theme="secondary" size="40" width="large" className="pr-10" onClick={onSubmit}>
                      保存する
                    </BaseButton>
                    <BaseButton theme="primary" size="40" width="large" onClick={onSubmit}>
                      反映を確認する
                    </BaseButton>
                  </div>
                </div>
              )}
            </div>
            <HeaderImage
              cameraIcon={true}
              imageSrc={
                portFolio?.profile?.main_image.f_url
                  ? portFolio?.profile?.main_image.f_url
                  : '/images/header-img_default.png'
              }
              onChange={setBannerImageUpdate}
              onClick={() => setBannerImageUpdate('')}
            />
          </>
        }
      >
        <div className="sm:mb-212">
          {/* 作品編集モーダルSP */}
          <SpEditModal
            isModal={spModal}
            portfolioItems={portFolioItems}
            onModal={setSpModal}
            handleDragEnd={handleDragEnd}
            removeModalOpen={removeModalOpen}
          />
          {/* 作品削除モーダル */}
          <WorksRemoveModal
            isModal={removeModal}
            onModal={setRemoveModal}
            removePortfolio={removePortfolio}
          />
          {/* 作品タイプ選択モーダル */}
          <WorkTypeSelectModal
            isModal={portfolioTypeModal}
            onModal={setPortfolioTypeModal}
            selectedTypeImage={selectedTypeImage}
            setPortfolioAddModal={setPortfolioAddModal}
            setPortfolioTypeModal={setPortfolioTypeModal}
            setSelectedTypeContents={setSelectedTypeContents}
          />
          {/* 作品追加モーダル */}
          <WorkAddModal
            isModal={portfolioAddModal}
            onModal={setPortfolioAddModal}
            selectedTypeContents={selectedTypeContents}
            postItemApi={postItemApi}
            returnModal={returnModal}
          />
          {/* 作品追加完了モーダル */}
          <WorksDoneModal
            isModal={doneModal}
            onModal={setDoneModal}
            setPortfolioTypeModal={setPortfolioTypeModal}
            portfolioItems={portFolioItems}
            userId={user?.user_id}
          />
          <PortfolioHeaderEdit
            imageSrc={user?.icon ? user.icon : '/images/circle-placeholder.png'}
            onChange={setUserUpdate}
            portFolio={portFolio}
          />
          <PortfolioNavigation
            menu={[
              { title: '作品', url: '/mypage/portfolio/edit' },
              { title: 'プロフィール', url: `/mypage/portfolio/${user?.user_id}/profile/edit` },
              { title: '設定', url: '/mypage/portfolio/setting' },
            ]}
            activeIndex={0}
          />
          {portFolioItems && portFolioItems.length > 0 && (
            <>
              <p className={cn('text-14_21', mq.sm ? 'mt-32' : 'mt-48')}>
                あなたの作品を登録しましょう。
                <br />
                登録数に制限なし。最大{limitVisibleItems}作品まで公開できます。
                <br />
                自作のポートフォリオの登録は
                <ButtonTextLink href="/mypage/self-portfolio/edit">こちらから</ButtonTextLink>
              </p>
              <section className="sp-edit_btn sp_only mt-18">
                <BaseButton
                  theme={'primary'}
                  size={'s'}
                  iconName={'Add'}
                  onClick={itemAdd}
                >
                  作品を追加する
                </BaseButton>
              </section>
              <section className="sp-edit_btn sp_only mb-18">
                <BaseButton
                  theme={'quaternary'}
                  size={'s'}
                  iconName={'Sortable'}
                  onClick={() => setSpModal(true)}
                >
                  並べ替えと削除
                </BaseButton>
              </section>
            </>
          )}
          {pending && (
            <WorkListSection
              portfolioItems={portFolioItems}
              handleDragEnd={handleDragEnd}
              removeModalOpen={removeModalOpen}
              onVisible={onVisible}
              linkTo={linkTo}
              itemAdd={itemAdd}
            />
          )}
        </div>
      </Page.Wrapper>
      <CropperModal
        isOpen={isOpen}
        width={IMAGE_SIZE.pf_main.width}
        height={IMAGE_SIZE.pf_main.height}
        src={buffer}
        postImage={onCropped}
        onClose={() => setIsOpen(false)}
      />
    </div>
  );
}

export default TopEdit;
